import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { io } from "socket.io-client";
import { ApiCalling } from "../../Services/pool";
import { useNavigate } from "react-router-dom";
const socket = io(ApiCalling.socketUrl.baseUrl);
const DisplayQuestion = () => {
  const navigate = useNavigate();
  const [QuestionData, setQuestionData] = useState("");
  const [isPlaying, setIsPlaying] = useState(true);
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    GetQuestionData();
  }, []);

  useEffect(() => {
    let interval;

    if (isPlaying) {
      setTimer(parseInt(QuestionData?.displayTime, 10));
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            setIsPlaying(false);
            clearInterval(interval);

            return 0;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying, QuestionData]);

  const GetQuestionData = () => {
    socket.on("viewQuestions", (data) => {
      if (data) {
        setQuestionData(data[0]);
      } else if (!data) {
        setQuestionData("");
      }
    });

    socket.on("sharedDataFromTeacher", (data) => {
      console.log("data====>", data);

      navigate("/dashboard/shareResult", {
        state: data,
      });
    });
    
  };

  return (
    <>
      <>
        <div className="kbc py-5">
          <Container>
            {/* Object.keys(QuestionData[currentQuestionIndex]).includes("displayTime") && */}
            {QuestionData.displayTime ? (
              <Row className="d-flex justify-content-center align-items-center">
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="p-3 d-flex flex-column align-items-center">
                    <div className="timerContainer">
                      <p>{timer}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div style={{ marginTop: "8%" }}></div>
            )}

            <Row className="justify-content-center gy-4 mt-3">
              <Col xs={12}>
                {QuestionData?.question ? (
                  <div className="question">
                    {" "}
                    <h5>{QuestionData?.question}</h5>{" "}
                  </div>
                ) : (
                  <h5 style={{ textAlign: "center" }}>No Live Question</h5>
                )}
              </Col>

              {/* {QuestionData?.options && QuestionData?.options.map((option, index) => (
                            <Col md={5} key={index} style={{ cursor: 'pointer' }}>
                                <div
                                    className={`answer ${selectedOption === index ? answerColors[index] : ''}`}
                                    onClick={() => handleOptionClick(index)}
                                >
                                    <h5>{option}</h5>
                                </div>
                            </Col>
                        ))} */}

              {QuestionData?.options &&
                QuestionData?.options.map((option, index) => (
                  <Col md={5} key={index} style={{ cursor: "pointer" }}>
                    <div className={`answer `}>
                      <h5>{option}</h5>
                    </div>
                  </Col>
                ))}
            </Row>
          </Container>
        </div>
      </>
    </>
  );
};

export default DisplayQuestion;
