import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuestionList } from "../Questions/slice/AllQuestionSlice";
import { Button, Col, Container, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import CustomTable from "../../Common/Table/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCalling } from "../../Services/pool";
import labels from "../../config/labels.json";

const QuestionListByTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const newChildWindowRef = useRef(null);
  const user = useSelector((state) => state.userData.loggedInUser);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const allQuestionDataList = useSelector(
    (state) => state.allQuestions.AllQuestion
  );

  console.log("kjsjbdasd", location.state);
  const getAllquestion = () => {
    const payload =
      user.role === "classteacher"
        ? {
            classId: user.classId,
            testId: location.state.test.testId,
          }
        : {
            testId: location.state.test.testId,
          };
    dispatch(getAllQuestionList(payload));
  };

  const handleClick = () => {
    const dataToSend = { classId: "4", subjectId: "5", studentId: "12" };

    // Construct the query string
    const queryString = new URLSearchParams(dataToSend).toString();

    // Use navigate to change the URL with query parameters
    navigate(`/dashboard/diplayquestiontoStudent?${queryString}`);
  };

  useEffect(() => {
    getAllquestion();
  }, []);

  // Send a message to the child window to expose the close function
  window.addEventListener("message", (event) => {
    if (event.data === "closeExtendedScreen") {
      closeExtendedScreen();
      window.close();
    }
  });

  const secondDislay = async (itm) => {
    const screenDetails = await window.getScreenDetails();
    if (window.screen.isExtended && screenDetails.screens.length > 1) {
      let query = `?question=${JSON.stringify(itm)}`;
      newChildWindowRef.current = window.open(
        `${ApiCalling.extensionUrl}/singlequiz` + query,
        "New Child Window",
        `popup,width=${screenDetails.screens[1].width},height=${
          screenDetails.screens[1].height
        },left=${0},top=${0},right=${0}`
      );
      newChildWindowRef.current.moveTo(screenDetails.screens[1].left, 0);

      // Use postMessage to send a message to the child window
      const messageData = { action: "startQuiz", data: itm };
      newChildWindowRef.current.postMessage(messageData, "*");

      navigate("/dashboard/singlequiz", {
        state: { data: itm },
      });
    } else {
      navigate("/dashboard/singlequiz", {
        state: { data: itm },
      });
    }
  };

  const closeExtendedScreen = () => {
    if (newChildWindowRef.current && !newChildWindowRef.current.closed) {
      newChildWindowRef.current.close();
    }
  };

  // for table data
  const headerData = [
    { header: labels.questions[selectedLang].srNo, field: "srNo" },
    { header: labels.questions[selectedLang].question, field: "question" },
    { header: labels.questions[selectedLang].class, field: "class" },
    { header: labels.questions[selectedLang].subject, field: "subject" },
    { header: labels.questions[selectedLang].action, field: "action" },
  ];

  const questionDataList =
    allQuestionDataList?.data !== undefined &&
    allQuestionDataList?.data !== null &&
    allQuestionDataList?.data.length > 0
      ? allQuestionDataList?.data.map((itm, index) => {
          return {
            srNo: index + 1,
            question: itm?.question,
            class: itm?.className,
            subject: itm?.subjectName,
            action: (
              <div>
                <span className="mb-2 d-flex">
                  <Button
                    className="primaryBtn"
                    onClick={() => {
                      secondDislay(itm);
                    }}
                  >
                    {labels.questions[selectedLang].StartQuestion}
                  </Button>
                </span>
                {/* <span className="d-flex justify-content-center">
                  <Button
                    className="primaryBtn w-50"
                    onClick={() =>
                      handleClick()
                    }
                  >
                    Student Test
                  </Button>
                </span> */}
              </div>
            ),
          };
        })
      : [];

  return (
    <div className="ViewQuestion py-4">
      <Row>
        <Col md={12}>
          <Button
            className="secondaryBtn me-3"
            style={{ fontSize: "15px" }}
            onClick={() => window.history.back()}
          >
            <Icon.ArrowLeftShort className="me-1" size={18} />
            Back
          </Button>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col md={12}>
            {/* <Button
              className="secondaryBtn me-3"
              style={{ fontSize: "15px" }}
              onClick={() => window.history.back()}
            >
              <Icon.ArrowLeftShort className="me-1" size={18} />
              Back
            </Button> */}
            <Button
              className="primaryBtn me-3 mx-auto"
              onClick={() =>
                navigate("/dashboard/addque", {
                  state: {
                    test: location.state.test,
                  },
                })
              }
            >
              <Icon.PlusCircleFill className="me-2" size={16} />
              {labels.questions[selectedLang].addQuestion}
            </Button>
          </Col>
        </Row>
        <hr className="w-100 mb-0" />
        <CustomTable headerData={headerData} headerRowData={questionDataList} />
      </Container>
    </div>
  );
};

export default QuestionListByTest;
