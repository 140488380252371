import { toast } from 'react-toastify';

// for error message
export const showToastError = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
    });
};

// for success message
export const showToastSuccess = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT
    });
};

// for warning message
export const showToastWarning = (message) => {
    toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT
    });
};

