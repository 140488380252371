import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const generatePdfFromCharts = async (chartIds, fileName) => {
    console.log("charts", chartIds)
  const pdf = new jsPDF();

    // Ensure chartIds is an array
    const ids = Array.isArray(chartIds) ? chartIds : [chartIds];

  for (let i = 0; i < ids.length; i++) {
    const chartElement = document.getElementById(ids[i]);
    if (!chartElement) {
      console.error(`Element with ID ${chartIds[i]} not found.`);
      continue;
    }

    const canvas = await html2canvas(chartElement);
    const imgData = canvas.toDataURL('image/png');

    if (i > 0) {
      pdf.addPage();
    }

    pdf.addImage(imgData, 'PNG', 10, 10, 180, 160); // Adjust size and position as needed
  }

  pdf.save(`${fileName}.pdf`);
};

export default generatePdfFromCharts;
