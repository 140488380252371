import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import ScrollToTop from "../src/Components/Responsive/ScrollToTop";
import CommonLayout from "./Components/Layout/CommonLayout";
import Login from "./Components/Auth/Login/Login";

import PageNotFound from "./Components/NoDataFound/PageNotFound";
import Home from "./Components/page/Home";
import Dashboard from "./Components/page/Dashboard";
import Other from "./Components/page/Other";
import AddQuestions from "./Components/Questions/AddQuestions";
import TestSubmitedPage from "./Components/TestSubmited/TestSubmitedPage";
import Questions from "./Components/page/Questions";
import ViewQuestionsList from "./Components/Questions/ViewQuestionsList";
import SingleQueQuiz from "./Common/KBC/SingleQueQuiz";
import SingleQueQuizeSchool from "./Common/KBC/SingleQueQuizeSchool";
import MultipleQueQuiz from "./Common/KBC/MultipleQueQuiz";
import Session from "./Components/Auth/Session/session";
import QuestionAnalysis from "./Common/Charts/ChartAnalysis/QuestionAnalysis";
import SessionWiseQueAnsList from "./Common/LiveQuestionAnsList/SessionWiseQueAnsList";
import SessionTable from "./Components/Auth/Session/SessionTable";
import Analytics from "./Components/page/Analytics";
import ShareResult from "./Components/page/ShareResult";
import LMSQuestions from "./Components/Questions/LMSQuestions";
import QuestionsNew from "./Components/page/QuestionNew";
import DisplayQuestion from "./Components/Questions/DisplayQuestion";
import DisplayQuestionStudent from "./Components/Questions/DisplayQuestionStudent";
import StudentList from "./Components/page/StudentList";
import StudentAnalytics from "./Components/page/StudentAnalytics";
import TestList from "./Components/Test/TestList";
import AddTest from "./Components/Test/AddTest";
import QuestionListByTest from "./Components/Test/QuestionListByTest";
import HandRaise from "./Components/page/HandRaise";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/singlequiz" element={<SingleQueQuizeSchool />} />
          <Route path="/shareResult" element={<ShareResult />} />

          {
            <Route path="/dashboard" element={<CommonLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/home" element={<Home />} />
              <Route path="/dashboard/que" element={<Questions />} />
              <Route path="/dashboard/quenew" element={<QuestionsNew />} />
              <Route path="/dashboard/other" element={<Other />} />
              <Route path="/dashboard/addque" element={<AddQuestions />} />
              <Route
                path="/dashboard/quelist"
                element={<ViewQuestionsList />}
              />
              <Route path="/dashboard/kbcque" element={<MultipleQueQuiz />} />
              <Route
                path="/dashboard/testSubmit"
                element={<TestSubmitedPage />}
              />
              <Route path="/dashboard/singlequiz" element={<SingleQueQuiz />} />
              <Route
                path="/dashboard/questionAnalysis"
                element={<QuestionAnalysis />}
              />
              <Route
                path="/dashboard/sessionWiseQueAns"
                element={<SessionWiseQueAnsList />}
              />
              <Route
                path="/dashboard/LMSQuestionList"
                element={<LMSQuestions />}
              />
              <Route path="/dashboard/session" element={<Session />} />
              {/* <Route path="/dashboard/analytics" element={<Analytics />} /> */}
              <Route
                path="/dashboard/sessionTable"
                element={<SessionTable />}
              />
              <Route
                path="/dashboard/diplayquestion"
                element={<DisplayQuestion />}
              />
              <Route path="/dashboard/studentList" element={<StudentList />} />
              <Route
                path="/dashboard/analytics/student"
                element={<StudentAnalytics />}
              />
              <Route path="/dashboard/testList" element={<TestList />} />
              <Route path="/dashboard/addTest" element={<AddTest />} />
              <Route path="/dashboard/questionListByTest" element={<QuestionListByTest />} />
              <Route path="/dashboard/hand-raise" element={<HandRaise />} />
            </Route>
          }
          <Route
            path="/diplayquestiontoStudent"
            element={<DisplayQuestionStudent />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
