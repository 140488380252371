import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {
  Button,
  Spinner,
  Accordion,
  Nav,
  OverlayTrigger,
  Tooltip,
  Popover,
  Form,
} from "react-bootstrap";
import PCMC from "../../Assets/PCMC-LOGO.png";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKey } from "../../Feature/Sidebar/ActiveKeySlice";
import { Col, FormControl, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import logo from "../../Assets/EASS.svg";
import { setSelectedLanguage } from "../../Feature/Sidebar/UserSlice";
import labels from "../../config/labels.json"
import useTimerComponent from "../ReuseApi/useTimerComponent";
import useQuestionStart from "../ReuseApi/useQuestionStart";
import Refresh from "../../Assets/Refresh.svg";
import { ApiCalling } from "../../Services/pool";

const Menubar = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userData.loggedInUser);
  const sessionStart = useSelector((state) => state.userData.sessionStartTime);
  
  const selectedLang = useSelector( (state) => state.userData.selectedLanguage )


  // usestate for loader
  const [loader, setLoader] = useState(false);
  const activeKeyRedux = useSelector(
    (state) => state.activeData.activeNumberKey
  );

  // function
  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute("data-visible");
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = "hidden";
    } else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = "auto";
    }
  }
  const handleLogout = () => {
    setLoader(true);
    dispatch(setActiveKey(0));
    navigate("/");
    localStorage.removeItem("persist:root");
    setLoader(false);
  };

  const entering = (e) => {
    e.children[0].style.borderTopColor = "white";
    e.children[1].style.backgroundColor = "white";
    e.children[0].style.borderRadius = "12px";
    e.children[1].style.borderRadius = "12px";
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className="PopupData">
          <div>
            <Icon.Person className="me-1" size={18} />
            <p>
              <strong>Name - </strong>Test
            </p>
          </div>
          <div>
            <Icon.Telephone className="me-2" size={14} />
            <p>
              <strong>Mobile - </strong>1234567890
            </p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const startHandRaise = async () => {
    let payload = {
      classId: user.classId,
      sessionId: "",
      flag: "start",
      schoolId:user.schoolId
    };

    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.startHandRaise}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    if(response.status === 200){
      
    }
  };

  return (
    <nav>
      <Row className="w-100 d-flex justify-content-between">
        <Col
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xxl={8}
          className="d-flex justify-content-start align-items-center m-0 p-0"
        >
          <abbr title="Refresh">
          <img
            src={Refresh}
            alt="Refresh"
            className="ms-2 refresh"
            style={{ width: "22px" }}
            onClick={() => {
              window.location.reload(false);
            }}
          />
        </abbr>
          <div>
            {useTimerComponent()}
          </div>
          <div>
            {useQuestionStart()}
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xxl={4}
          className="d-flex justify-content-end align-items-center d-none d-lg-flex"
        >

          <div
            className="d-flex justify-content-xs-start justify-content-sm-start justify-content-md-start  justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <h5 className="m-0 ">{user.name}</h5>
            <p className="m-0" style={{ textTransform: "capitalize", fontSize:'14px', color:'grey' }}>
            {labels.menubar[selectedLang].role} - {user.role}
            </p>
          </div>
          <div style={{ marginLeft:15 }}>
            <Button className="primaryBtn" style={{ width:100 }} onClick={ () => {
              if(selectedLang === 'odia'){
                dispatch(setSelectedLanguage('en'))
              }else{
                dispatch(setSelectedLanguage('odia'))
              }
            } }>
              {selectedLang === 'odia' ? "English" : "Odia" }
            </Button>
          </div>
        </Col>
      </Row>

      <div className="d-flex align-items-center">
        {/* <Icon.PersonFill className='me-2 d-block d-lg-none' size={35} style={{ cursor: 'pointer' }} /> */}
        <div
          className="mobile-nav-toggle"
          aria-controls="selectNav"
          aria-expanded="false"
          onClick={setnav}
        >
          <div className="menu-btn__burger"></div>
        </div>
      </div>

      <ul id="selectNav" className="mobile-nav" data-visible="false">
        <div className={styles.headerDiv}>
          <div className="mt-2 text-center ">
            <div
              className="d-flex justify-content-xs-start justify-content-sm-start justify-content-md-start  justify-content-center align-items-center"
              style={{ flexDirection: "column" }}
            >
              <h5 className="m-0 ">{user.name}</h5>
              <h6 className="m-0" style={{ textTransform: "capitalize" }}>
                {user.role}
              </h6>
            </div>

            {/* <img src={logo} width={200} className="mb-2" /> */}
          </div>
        </div>

        <div className="d-flex justify-content-between flex-column h-100 mt-3">
        <Accordion flush activeKey={activeKeyRedux?.activeKey}>
            {user.role === "admin" ||
              (user.role === "studioteacher" && user.role !== "schoollogin" && (
                <Accordion.Item
                  eventKey={5}
                  onClick={() => {
                    handleAccordionChange(
                      5,
                      5,
                      labels.menubar[selectedLang].createSession
                    );
                    navigate("/dashboard/session");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 5 ? "activeMenu" : "menuItem"
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 0 ? (
                      <Icon.BarChartFill
                        className={`me-2 icon ${styles.rowIcon}`}
                      />
                    ) : (
                      <Icon.BarChart
                        className={`me-2 icon ${styles.rowIcon}`}
                      />
                    )}
                    <div className={styles.rowName}>
                      {labels.menubar[selectedLang].createSession}
                    </div>
                  </Accordion.Header>
                </Accordion.Item>
              ))}

            {user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={0}
                onClick={() => {
                  handleAccordionChange(
                    0,
                    0,
                    labels.menubar[selectedLang].dashboard
                  );
                  navigate("/dashboard");
                }}
                className={
                  activeKeyRedux?.activeKey === 0 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 0 ? (
                    <Icon.BarChartFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.BarChart className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].dashboard}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/dashboard/home"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : "menuItem"}>
                                          <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className={`me-2 icon ${styles.rowIcon}`} /> : <Icon.HouseDoor className={`me-2 icon ${styles.rowIcon}`} />}<div className={styles.rowName}>Home</div></Accordion.Header>
                                    </Accordion.Item> */}
            {user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(
                    2,
                    2,
                    labels.menubar[selectedLang].question
                  );
                  navigate("/dashboard/quenew");
                }}
                className={
                  activeKeyRedux?.activeKey === 2 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 2 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].question}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* {user.role !== "classteacher" && (
              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(2, 2, "Add Question");
                  navigate("/dashboard/que");
                }}
                className={
                  activeKeyRedux?.activeKey === 2 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 2 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>Questions</div>
                </Accordion.Header>
              </Accordion.Item>
            )} */}

            {/* <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Other"); navigate("/dashboard/other"); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : "menuItem"}>
                                          <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.PersonFillGear className={`me-2 icon ${styles.rowIcon}`} /> : <Icon.PersonGear className={`me-2 icon ${styles.rowIcon}`} />}<div className={styles.rowName}>Other</div></Accordion.Header>
                                    </Accordion.Item> */}

            {user.role !== "studioteacher" && user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={4}
                onClick={() => {
                  handleAccordionChange(
                    4,
                    4,
                    labels.menubar[selectedLang].liveSessionPoll
                  );
                  navigate("/dashboard/sessionWiseQueAns");
                }}
                className={
                  activeKeyRedux?.activeKey === 4 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 4 ? (
                    <Icon.Broadcast className={`me-2 icon ${styles.rowIcon}`} />
                  ) : (
                    <Icon.Broadcast className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].liveSessionPoll}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role !== "classteacher" && user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={6}
                onClick={() => {
                  handleAccordionChange(
                    6,
                    6,
                    labels.menubar[selectedLang].sessionList
                  );
                  navigate("/dashboard/sessionTable");
                }}
                className={
                  activeKeyRedux?.activeKey === 6 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 6 ? (
                    <Icon.PersonFillGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.PersonGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].sessionList}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role !== "admin" && user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={7}
                onClick={() => {
                  handleAccordionChange(7, 7, "Assessment List");
                  navigate("/dashboard/testList");
                }}
                className={
                  activeKeyRedux?.activeKey === 7 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 7 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>{labels.menubar[selectedLang].testList}</div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* {user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={8}
                onClick={() => {
                  handleAccordionChange(8, 8,labels.menubar[selectedLang].lmsQuestions);
                  navigate("/dashboard/LMSQuestionList");
                }}
                className={
                  activeKeyRedux?.activeKey === 8 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 8 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>{labels.menubar[selectedLang].lmsQuestions}</div>
                </Accordion.Header>
              </Accordion.Item>
            )} */}

            {user.role == "schoollogin" && (
              <Accordion.Item
                eventKey={8}
                onClick={() => {
                  handleAccordionChange(
                    8,
                    8,
                    labels.menubar[selectedLang].displayQuestions
                  );
                  navigate("/dashboard/diplayquestion");
                }}
                className={
                  activeKeyRedux?.activeKey === 8 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 8 ? (
                    <Icon.BarChartFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.BarChart className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].displayQuestions}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role == "classteacher" && (
              <Accordion.Item
                eventKey={8}
                onClick={() => {
                  handleAccordionChange(
                    9,
                    9,
                    labels.menubar[selectedLang].studentList
                  );
                  navigate("/dashboard/studentList");
                }}
                className={
                  activeKeyRedux?.activeKey === 9 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 9 ? (
                    <Icon.PeopleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.People className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].studentList}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role == "classteacher" && (
              <Accordion.Item
                eventKey={10}
                onClick={() => {
                  handleAccordionChange(
                    10,
                    10,
                    labels.menubar[selectedLang].studentAnalytics
                  );
                  navigate("/dashboard/analytics/student");
                }}
                className={
                  activeKeyRedux?.activeKey === 10 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 10 ? (
                    <Icon.BarChartFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.BarChart className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].studentAnalytics}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* <Accordion.Item
                eventKey={10}
                onClick={() => {
                  handleAccordionChange(10, 10, labels.menubar[selectedLang].analytics);
                  navigate("/dashboard/analytics");
                }}
                className={
                  activeKeyRedux?.activeKey === 10 ? "activeMenu" : "menuItem"
                }>
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 10 ? (
                    <Icon.PersonFillGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.PersonGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>{labels.menubar[selectedLang].analytics}</div>
                </Accordion.Header>
              </Accordion.Item> */}

{user.role == "classteacher" && (
              <Accordion.Item
                eventKey={11}
                onClick={() => {
                  handleAccordionChange(
                    11,
                    11,
                    labels.menubar[selectedLang].handRaise
                  );
                  startHandRaise();
                  navigate("/dashboard/hand-raise");
                }}
                className={
                  activeKeyRedux?.activeKey === 11 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 11 ? (
                    <Icon.HandIndexThumbFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.HandIndexThumb
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].handRaise}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
        <div className="px-2 mt-auto">
          <Button className="btn-logout mx-auto" onClick={handleLogout}>
            {loader ? (
              <Spinner animation="border" variant="light" className="spinner" />
            ) : (
              <>
                <Icon.BoxArrowLeft className="me-2" />
                Logout
              </>
            )}
          </Button>
        </div>
      </ul>
    </nav>
  );
};

const useStyles = makeStyles({
  headerDiv: {
    width: "100%",
    height: "10%",
    display: "flex",
    justifyContent: "center",
  },
  headerLogoDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitleDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.5rem",
    color: "rgba(124, 31, 168, 1)",
    fontWeight: "600",
  },
  headerLogo: {
    width: "60%",
    height: "80%",
    borderRadius: "50%",
  },
  menuDiv: {
    width: "85%",
    margin: "15px",
  },
  menuItem: {
    width: "100%",
    padding: "12px 10px",
    margin: "14px 10px 10px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "none",
    borderRadius: "0px",
    fontWeight: "500",
    color: "#696F7B",
    "&:hover": {
      background: "#081225",
      color: "white",
      cursor: "pointer",
    },
  },
  activeItem: {
    width: "100%",
    padding: "12px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "none",
    margin: "0px 10px 0px 10px",
    borderRadius: "50px",
    backgroundColor: "#081225",
    color: "white",
  },
  rowIcon: {
    marginLeft: "10%",
    scale: 1.2,
  },
  rowName: {
    marginLeft: "5%",
    fontWeight: "600",
  },
  searchContainer: {
    width: "100%",
    position: "relative",
  },
  filterLogo: {
    width: "100%",
    height: "90%",
  },
  searchField: {
    margin: "0px",
    padding: "5px 0px",
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "80%",
    transform: "translate(100%, -50%)",
    color: "gray",
  },
});

export default Menubar;
