import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import Menubar from '../Responsive/Menubar'
import Sidebar from '../Responsive/Sidebar';
import { useSelector } from 'react-redux';

const CommonLayout = () => {

  return (
    <div>
      <Container fluid>
        <Row>
          <div className='sidenav-lg-parent'>
            <Col className='ps-0 d-none d-lg-block'>
              <Sidebar />
            </Col>
            <Col>
              <div className='outletDiv'>
                <Menubar />
                <div className='outlet'>
                  <Outlet />
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default CommonLayout;