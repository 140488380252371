import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  setSessionDetails,
  setSessionStartTime,
  setSessionEndTime,
  emptySession,
} from "../../../Feature/Sidebar/UserSlice";
import session from "../../../Assets/session.svg";
import * as Icon from "react-bootstrap-icons";
import Select from "react-select";
import { ApiCalling } from "../../../Services/pool";
import useGetAllClass from "../../ReuseApi/useGetAllClass";
import useGetAllSubject from "../../ReuseApi/useGetAllSubjects";
import useGetAllTopic from "../../ReuseApi/useGetAllTopics";
import { showToastSuccess, showToastWarning } from "../../Utilities/toastutils";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import CustomTable from "../../../Common/Table/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import labels from "../../../config/labels.json";
import { toast } from "react-toastify";

const Session = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [studioTeacherList, setStudioTeacherList] = useState([]);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);

  const [sessionName, setSessionName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [className, setClassName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [topicName, setTopicName] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [error, setError] = useState("");

  const [sessionNameError, setSessionNameError] = useState(false);
  const [classNameError, setClassNameError] = useState(false);
  const [teacherNameError, setTeacherNameError] = useState(false);
  const [subjectNameError, setSubjectNameError] = useState(false);
  const [topicNameError, setTopicNameError] = useState(false);
  const [sessionDateError, setSessionDateError] = useState(false);
  const [sessionStartTimeError, setSessionStartTimeError] = useState(false);
  const [sessionEndTimeError, setSessionEndTimeError] = useState(false);

  const [liveSessions, setLiveSessions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sessionloader, setSessionLoader] = useState(false);
  const [endloader, setEndLoader] = useState(false);

  useEffect(() => {
    getStudioTeacherList();
    getLiveSessionList();
  }, []);

  const getLiveSessionList = async () => {
    setLoader(true);
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.liveSessionList}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    );
    const responseData = await response.json();
    if (responseData.Error === null) {
      setLoader(false);
      console.log("live Session",responseData)
      const sortedArray = responseData.Entities.sort(
        (a, b) => a.SessionStatus - b.SessionStatus
      );
      setLiveSessions(sortedArray);
    } else {
      setLoader(false);
      toast.error(responseData.Error);
    }
  };

  const showModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleValidate = () => {
    let FormValid = true;

    if (sessionName.length === 0) {
      FormValid = false;
      setSessionNameError(true);
    } else if (className.length === 0) {
      FormValid = false;
      setClassNameError(true);
    } else if (teacherName.length === 0) {
      FormValid = false;
      setTeacherNameError(true);
    } else if (subjectName.length === 0) {
      FormValid = false;
      setSubjectNameError(true);
    } else if (topicName.length === 0) {
      FormValid = false;
      setTopicNameError(true);
    } else if (startTime.length === 0) {
      FormValid = false;
      setSessionStartTimeError(true);
    } else if (endTime.length === 0) {
      FormValid = false;
      setSessionEndTimeError(true);
    }
    return FormValid;
  };

  const createSession = async (e) => {
    console.log("outside");
    e.preventDefault();
    if (handleValidate()) {
      setSessionLoader(true);
      console.log("inside if");
      const payload = {
        sessionName: sessionName,
        teacherId: teacherName.value,
        teacherName: teacherName.label,
        classId: className.value,
        subjectId: subjectName.value,
        topicId: topicName.value,
        className: className.label,
        subjectName: subjectName.label,
        topicName: topicName.label,
        sessionStartTime: startTime,
        sessionEndTime: endTime,
        sessionDate: startTime,
      };
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.createSession}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      if (responseData?.message === "success") {
        setSessionLoader(false);
        showToastSuccess("Session created successfully.");
        setShow(false);
        setSessionName("");
        setTeacherName("");
        setClassName("");
        setSubjectName("");
        setTopicName("");
        setStartTime("");
        setEndTime("");
        navigate("/dashboard/sessionTable");
        // navigate("/sessionTable")
      } else {
        setSessionLoader(false);
        toast.error(responseData.message);
      }
    }
  };

  const getStudioTeacherList = async () => {
    const payload = { role: "studioteacher" };
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.getTeacherList}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    const filterOption =
      responseData.data?.length > 0 &&
      responseData.data?.map((itm) => {
        return { value: itm.userId, label: itm.name };
      });
    console.log("filterOption", filterOption);
    setStudioTeacherList(filterOption);
  };

  const handleStartTimeChange = (e) => {
    const newStartTime = e.target.value;

    // Check if new start time is greater than or equal to end time
    if (newStartTime <= endTime || !endTime) {
      setStartTime(newStartTime);
      setError("");
    } else {
      setError("Start time should not be greater than end time");
    }
  };

  const handleEndTimeChange = (e) => {
    const newEndTime = e.target.value;

    // Check if new end time is greater than or equal to start time
    if (newEndTime >= startTime || !startTime) {
      setEndTime(newEndTime);
      setError("");
    } else {
      setError("End time should not be less than start time");
    }
  };

  const getclassList = useGetAllClass();
  const getsubjectList = useGetAllSubject();
  const getTopicList = useGetAllTopic();

  const headerData = [
    // { header: "Sr.No", field: "srNo" },
    {
      header: labels.createSession[selectedLang].sessionId,
      field: "sessionId",
    },
    { header: labels.createSession[selectedLang].sessionName, field: "name" },
    {
      header: labels.createSession[selectedLang].startDate,
      field: "startDate",
    },
    { header: labels.createSession[selectedLang].endDate, field: "endDate" },
    { header: labels.createSession[selectedLang].action, field: "action" },
  ];

  const LiveSessionData =
    liveSessions !== undefined &&
    liveSessions !== null &&
    liveSessions.length > 0
      ? liveSessions.map((itm, index) => {
          return {
            srNo: index + 1,
            sessionId: itm?.Id,
            name: itm?.Name,
            startDate: itm?.SessionStartTime
              ? moment(itm.SessionStartTime).format("DD-MMM-YYYY")
              : "-",
            endDate: itm?.SessionEndTime
              ? moment(itm.SessionEndTime).format("DD-MMM-YYYY")
              : "-",
            action: (
              <span className="d-flex">
                {itm?.SessionStatus === 1 ? (
                  <Button
                    className="sessionLive"
                    onClick={() => endLiveSession(itm)}
                  >
                    {labels.createSession[selectedLang].endSession}
                  </Button>
                ) : (
                  <span className="">
                    {labels.createSession[selectedLang].sessionEnded}
                  </span>
                )}
              </span>
            ),
          };
        })
      : [];

  const endLiveSession = async (session) => {
    setEndLoader(true)
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.endLivession}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ProductId: session.ProductId,
          lmsessionId: session.Id,
        }),
      }
    );
    const responseData = await response.json();
    if (responseData.Error === null) {
      getLiveSessionList();
      dispatch(setSessionEndTime(new Date().toLocaleTimeString()));
      dispatch(emptySession());
    setEndLoader(false)
    }else{
    setEndLoader(false)
      toast.error(responseData.Error)
    }
  };

  return (
    <>
      <div className="session">
        <Modal
          show={show}
          onHide={closeModal}
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            closeButton
            style={{
              color: "black",
              width: "100%",
              height: "50px",
              // backgroundColor: "#F9D65F",
            }}
          >
            <Modal.Title style={{ fontWeight: "bold" }}>
              {labels.createSession[selectedLang].createSession}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body size="lg">
            <Row className="mb-4 m-3">
              <Col sm={12} md={12}>
                <Form.Group>
                  <Form.Label className="mt-3 fw-bold">
                    {labels.createSession[selectedLang].sessionName}
                    <span className="requiredfield"> *</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder={
                        labels.createSession[selectedLang].sessionName
                      }
                      className="input-field project-inputgroup"
                      name="sessionName"
                      value={sessionName}
                      onChange={(e) => {
                        if (e.target.value.trim()) {
                          setSessionName(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setSessionName(e.target.value);
                        }
                        sessionName.length >= 0
                          ? setSessionNameError(false)
                          : setSessionNameError(true);
                      }}
                    />
                  </InputGroup>
                  {sessionNameError ? (
                    <p className="errMsg">
                      <>
                        <Icon.ExclamationTriangle className="me-2" />
                      </>
                      Required Field
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4 m-3">
              <Col sm={12} md={6}>
                <Form.Group className="">
                  <Form.Label className="mt-3 fw-bold">
                    {labels.createSession[selectedLang].className}
                    <span className="requiredfield">*</span>
                  </Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="className"
                    value={className}
                    options={getclassList}
                    onChange={(option) => {
                      setClassName(option);
                      setSubjectName("");
                      setSubjectNameError(false);
                      className.length < 0
                        ? setClassNameError(true)
                        : setClassNameError(false);
                    }}
                  />
                  {classNameError ? (
                    <p className="errMsg">
                      <>
                        <Icon.ExclamationTriangle className="me-2" />
                      </>
                      Required Field
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col sm={12} md={6}>
                <Form.Group className="">
                  <Form.Label className="mt-3 fw-bold">
                    {labels.createSession[selectedLang].teacherName}
                    <span className="requiredfield">*</span>
                  </Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="teacherName"
                    value={teacherName}
                    options={studioTeacherList}
                    onChange={(option) => {
                      setTeacherName(option);
                      teacherName.length < 0
                        ? setTeacherNameError(true)
                        : setTeacherNameError(false);
                    }}
                  />
                  {teacherNameError ? (
                    <p className="errMsg">
                      <>
                        <Icon.ExclamationTriangle className="me-2" />
                      </>
                      Required Field
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4 m-3">
              <Col sm={12} md={6}>
                <Form.Group className="">
                  <Form.Label className="mt-3 fw-bold">
                    {labels.createSession[selectedLang].subjectName}
                    <span className="requiredfield">*</span>
                  </Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="subjectName"
                    value={subjectName}
                    options={getsubjectList.filter(
                      (row) =>
                        className &&
                        row.ClassId &&
                        row.ClassId === className.value
                    )}
                    isDisabled={!className}
                    onChange={(option) => {
                      setSubjectName(option);
                      setTopicName("");
                      setTopicNameError(false);
                      subjectName.length < 0
                        ? setSubjectNameError(true)
                        : setSubjectNameError(false);
                    }}
                  />
                  {subjectNameError ? (
                    <p className="errMsg">
                      <>
                        <Icon.ExclamationTriangle className="me-2" />
                      </>
                      Required Field
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col sm={12} md={6}>
                <Form.Group className="">
                  <Form.Label className="mt-3 fw-bold">
                    {labels.createSession[selectedLang].topicName}
                    <span className="requiredfield">*</span>
                  </Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="topicName"
                    value={topicName}
                    options={getTopicList.filter(
                      (row) =>
                        subjectName &&
                        row.SubjectId &&
                        row.SubjectId === subjectName.value
                    )}
                    isDisabled={!subjectName}
                    onChange={(option) => {
                      setTopicName(option);
                      topicName.length < 0
                        ? setTopicNameError(true)
                        : setTopicNameError(false);
                    }}
                  />
                  {topicNameError ? (
                    <p className="errMsg">
                      <>
                        <Icon.ExclamationTriangle className="me-2" />
                      </>
                      Required Field
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              {/* <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label className="mt-3 fw-bold">
                  Session Date
                  <span className="requiredfield"> *</span>
                </Form.Label>
                 <Form.Control type="date" onKeyDown={(event) => event.preventDefault()}
                  name="sessionDate" value={date}
                  onChange={(e) => {
                    setDate(e.target.value)
                    date.length < 0 ?
                    setSessionDateError(true) :
                    setSessionDateError(false)}}
                />
                {sessionDateError ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>  */}
            </Row>

            <Row className="mb-4 m-3">
              {/* <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Session Start Time
                <span className="requiredfield">*</span>
              </Form.Label>
              <input type="time" name='sessionStartTime'  class="form-control" value={startTime} 
                onChange={(e) =>{
                  const newStartTime = e.target.value;
                  setStartTime(newStartTime)

                  if (endTime.length > 0 && newStartTime >= endTime) {
                    showToastWarning("Start time should not be less than end time")
                  } 

                  startTime.length > 0 && endTime.length > 0 && newStartTime >= endTime
                  ? setSessionStartTimeError(true)
                  : setSessionStartTimeError(false);
                }}
              />
              {sessionStartTimeError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null }
            </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
               Session End Time
                <span className="requiredfield">*</span>
              </Form.Label>
             
              <input type="time" name='sessionEndTime'  class="form-control" value={endTime} 
              onChange={(e) =>{
                const newEndTime = e.target.value;

                // Validate Session End Time
                if (startTime.length > 0 && startTime >= newEndTime) {
                  showToastWarning("End time must be greater than the start time.");
                } 

                setEndTime(e.target.value)
                endTime.length > 0 && startTime.length > 0 && startTime >= newEndTime?
                setSessionEndTimeError(true) :
                setSessionEndTimeError(false)
              }}  
              />
              {sessionEndTimeError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
            </Col>   */}

              <Col sm={12} md={6}>
                <Form.Group className="">
                  <Form.Label className="mt-3 fw-bold">
                    {labels.createSession[selectedLang].sessionStartDate}
                    <span className="requiredfield">*</span>
                  </Form.Label>
                  <Form.Control
                    className=""
                    type="datetime-local"
                    name="sessionStartTime"
                    value={startTime}
                    min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      e.target.value.length < 0
                        ? setSessionStartTimeError(true)
                        : setSessionStartTimeError(false);
                    }}
                  />
                  {sessionStartTimeError ? (
                    <p className="errMsg">
                      <>
                        <Icon.ExclamationTriangle className="me-2" />
                      </>
                      Required Field
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col sm={12} md={6}>
                <Form.Group className="">
                  <Form.Label className="mt-3 fw-bold">
                    {labels.createSession[selectedLang].sessionEndDate}
                    <span className="requiredfield">*</span>
                  </Form.Label>
                  <Form.Control
                    className=""
                    type="datetime-local"
                    name="sessionStartTime"
                    value={endTime}
                    min={startTime}
                    disabled={startTime?.length === 0 ? true : false}
                    onChange={(e) => {
                      setEndTime(e.target.value);

                      e.target.value.length < 0
                        ? setSessionEndTimeError(true)
                        : setSessionEndTimeError(false);
                    }}
                  />
                  {sessionEndTimeError ? (
                    <p className="errMsg">
                      <>
                        <Icon.ExclamationTriangle className="me-2" />
                      </>
                      Required Field
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="text-center mt-4">
            {sessionloader ? (
              <center>
                <Spinner />
              </center>
            ) : (
              <Button
                className="primaryBtn "
                onClick={(e) => {
                  createSession(e);
                }}
              >
                <Icon.PersonAdd className="me-2" />{" "}
                {labels.createSession[selectedLang].createSession}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Container>
          <Row
            className="justify-content-between align-items-center p-5"
            data-aos="fade-up"
          >
            <Col md={6} className="text-center">
              <h1>{labels.createSession[selectedLang].createStudioLive} </h1>
              <p>{labels.createSession[selectedLang].loremIpsum} </p>
              <Button
                className="primaryBtn mx-auto mt-5"
                onClick={() => showModal()}
              >
                <Icon.BoxArrowInRight size={18} className="me-2" />
                {labels.createSession[selectedLang].createSession}
              </Button>
            </Col>

            <Col md={6} className="text-center">
              <img
                src={session}
                className="img-fluid mt-5"
                alt="Page Not Found"
              />
            </Col>
          </Row>
          <Row>
            <h5 className="ms-5" style={{ width: "90%" }}>
              *{labels.createSession[selectedLang].liveSessionList}{" "}
            </h5>
            {loader ? (
              <center>
                <Spinner />
              </center>
            ) : (
              <CustomTable
                headerData={headerData}
                headerRowData={LiveSessionData}
              />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Session;
