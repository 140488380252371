import React, { useEffect, useState } from 'react';
import { ApiCalling } from '../../Services/pool';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const StudentAnalytics = () => {
    const user = useSelector((state) => state.userData.loggedInUser);

    const [studentList, setStudentList] = useState([]);
    const [studentId, setStudentId] = useState('');

    useEffect(() => {
        getStudents();
    }, []);

    const getStudents = async () => {
        try {
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.studentList}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        classId: user.classId,
                        schoolId: user.schoolId,
                    }),
                }
            );

            const responseData = await response.json();
            setStudentList(responseData.data);

            // Set the default student ID to the first student in the list
            if (responseData.data.length > 0) {
                setStudentId(responseData.data[0].rollNo);
            }
        } catch (Err) {
            console.log("Err while getting students", Err);
        }
    }

    // Only render the iframe if the student list is not empty
    const renderIframe = () => {
        if (studentList.length === 0) {
            return null;
        }
        return (
            <iframe
                title="Student Analytics"
                width="100%"
                height="600"
                src={`https://odisha.analytics.foxberry.live/student/${studentId}`}
                frameBorder="0"
                allowFullScreen={true}
            />
        );
    }

    return (
        <div className='StudentAnalytics'>
            <Container>
                <Row className="justify-content-end">
                    <Col md={3}>
                        <Form.Select value={studentId} onChange={(e) => setStudentId(e.target.value)}>
                            {studentList.length > 0 ? (
                                studentList.map((row) => (
                                    <option key={row.rollNo} value={row.rollNo}>{row.name} - {row.rollNo}</option>
                                ))
                            ) : (
                                <option value="">No Student Found</option>
                            )}
                        </Form.Select>
                    </Col>
                </Row>

                <Row>
                    {renderIframe()}
                </Row>
            </Container>
        </div>
    );
}

export default StudentAnalytics;
