import React from "react";
import { Button, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const CustomTable = ({ headerData, headerRowData = [] }) => {
  return (
    <div>
      <div className="outer-wrapper mx-auto">
        <div className="table-wrapper">
          <Table className="table text-center custom-table" hover>
            <thead>
              <tr>
                {headerData?.map((header, index) => (
                  <th key={index} scope="col" style={{ textAlign: "center" }}>
                    {header.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {headerRowData?.map((item, rowIndex) => (
                <tr
                  key={rowIndex}
                  style={{ textAlign: "left", border: "1px solid transparent" }}
                >
                  {headerData ? (
                    headerData.map((column, colIndex) => (
                      <td
                        style={{
                          width: "10%",
                          fontSize: "13px",
                          ...column.style,
                        }}
                        key={colIndex}
                      >
                        {item[column.field]}
                      </td>
                    ))
                  ) : (
                    <td>No Data Found</td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
