import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { ApiCalling } from "../../Services/pool";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { showToastSuccess } from "../Utilities/toastutils";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import CustomTable from "../../Common/Table/CustomTable";

const LMSQuestions = () => {
  const [questionList, setQuestionList] = useState([]);

  const getLMSQuestion = async () => {
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.getLMSquestions}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    );

    const responseData = await response.json();
    setQuestionList(responseData.Entities);
  };

  const headerData = [
    { header: "Sr.No", field: "srNo" },
    { header: "Questions", field: "question" },
    { header: "Class Id", field: "classId" },
    { header: "Subject Id", field: "subjectId" },
    { header: "Created Date", field: "createdDate" },
    { header: "Retrive text", field: "retriveText" }
  ];

  const questionDataList =
    questionList !== undefined &&
      questionList !== null &&
      questionList.length > 0
      ? questionList.map((itm, index) => {
        return {
          srNo: index + 1,
          question: (<span dangerouslySetInnerHTML={{ __html: itm?.Question }}></span>),
          classId: itm?.ClassId,
          subjectId: itm?.SubjectId,
          createdDate: moment(itm.InsertDate).format("DD-MMM-YYYY"),
          retriveText: (
            <span className="d-flex justify-content-center">
              <button
                className="primaryBtn "
                onClick={() =>
                  retrieveText(itm.Question)
                }
              >
                Retrive
              </button>
            </span>
          )
        };
      })
      : [];

  const retrieveText = (question) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = question;
    const spanElement = tempElement.querySelector('span');
    const textContent = spanElement.textContent || spanElement.innerText;
  };

  useEffect(() => {
    getLMSQuestion();
  }, []);

  return (
    <div>
      {questionList.length > 0 ? (
        <CustomTable headerData={headerData} headerRowData={questionDataList} />
      ) : <div className=" m-2 d-flex justify-content-center align-items-center">
        <h3>No data found</h3>
        </div>}
    </div>
  );
};

export default LMSQuestions;
