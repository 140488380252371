import React, { useEffect, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { showToastSuccess, showToastError } from "../../Utilities/toastutils";
import { ApiCalling } from "../../../Services/pool";
import { setUser,setSelectedLanguage } from "../../../Feature/Sidebar/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKey } from "../../../Feature/Sidebar/ActiveKeySlice";
import backgroundImage from "../../../Assets/Image/LoginImage.avif";
import Select from "react-select";
import labels from "../../../config/labels.json";
import logo from '../../../Assets/odish-logo.png';

const Login = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedLang = useSelector( (state) => state.userData.selectedLanguage )

  // Default data for mobile number and password
  const defaultMobileNumber = "1234567890";
  const defaultPassword = "123";

  // for password show and hide
  const [showPass, setShowPass] = useState(false);

  // state for login
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");

  // state for validation
  const [mobilenumberError, setMobileNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // for loader
  const [loading, setLoading] = useState(false);

  const [language,setLang] = useState({ label:'English',value:'en' },)

  const langOptions = [
    { label:'English',value:'en' },
    { label:'Odia (Oriya)',value:'odia' }
  ]

  // for handler login
  const handleLogin = async (e) => {
    e.preventDefault();

    // login form validation
    if (!mobileNumber) {
      setMobileNumberError("Please enter a mobile number.");
    } else if (mobileNumber.length !== 10) {
      setMobileNumberError("Mobile number must be 10 digits.");
    } else if (!password) {
      setPasswordError("Please enter a password.");
    } else {
      setMobileNumberError("");
      setPasswordError("");
      setLoading(true);

      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.login}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            mobile: mobileNumber,
            password: password,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.status === 200) {
        console.log(responseData.data.role);
        dispatch(setUser(responseData.data));
        showToastSuccess("Login Successfully");
        setLoading(false);
        // if (responseData.data.role === "admin") {
        //   navigate("/session");
        // }else{
          if (responseData.data.role === "schoollogin") {
            console.log("school login---",responseData.data.role);
            navigate("/dashboard/diplayquestion");
            dispatch(
              setActiveKey({
                activeKey: 8,
                subActiveKey: 0,
                activeTab: labels.menubar[selectedLang].displayQuestions,
              })
            );
          }else if (responseData.data.role === "studioteacher") {
            console.log("inside if else---",responseData.data.role);
            navigate("/dashboard/session");
            dispatch(
              setActiveKey({
                activeKey: 5,
                subActiveKey: 0,
                activeTab: labels.menubar[selectedLang].createSession,
              })
            );
          } else {
            console.log("inside else---",responseData.data.role);
          navigate("/dashboard");
          dispatch(
            setActiveKey({
              activeKey: 0,
              subActiveKey: 0,
              activeTab: labels.menubar[selectedLang].dashboard,
            })
          );
        }

        // }
      } else {
        showToastError(responseData.message);
        setLoading(false);
      }
    }
  };

  // function for mobile onchange
  const setMobileNumberHandler = (value) => {
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setMobileNumber(limitedValue);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftSection}>
        <div>
          <img src={logo} width={380} height={400}/>
          <h5 className="mt-2">{labels?.login[selectedLang].subTitle}</h5>
          <h2 className="loginTitle">{labels?.login[selectedLang].title}</h2>
        </div>
      </div>

      <div className={styles.rightSection}>
        <div className={styles.loginContainer}>
          <Form className="login-form" onSubmit={handleLogin}>
            <h2 className={styles.loginTitle}>
              {/* <Icon.PersonCircle size={30} /> {labels.login[selectedLang].heading} */}
              <img src={logo} width={80} height={80} className="mx-auto" />
            </h2>
            <p className="text-center subHeading">
              <Icon.BoxArrowInRight className="me-2" />
              {labels.login[selectedLang].loginToYourAccount}
            </p>
            <Form.Group
              className="mb-3"
            >
              <Form.Label className="fw-bold">
              {labels.login[selectedLang].mobileNo}<span> *</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <Icon.Phone size={25} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Enter Mobile No"
                  value={mobileNumber}
                  onChange={(e) => {
                    setMobileNumberHandler(e.target.value);
                    setMobileNumberError("");
                  }}
                />
              </InputGroup>
              {mobilenumberError && (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  {mobilenumberError}
                </p>
              )}
            </Form.Group>

            <Form.Group  className="mb-4">
              <Form.Label className="fw-bold">
                {" "}
                {labels.login[selectedLang].password}<span> *</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  {showPass ? (
                    <Icon.EyeSlash
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass(!showPass)}
                    />
                  ) : (
                    <Icon.Eye
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass(!showPass)}
                    />
                  )}
                </InputGroup.Text>
                <Form.Control
                  placeholder="Enter Password"
                  type={showPass ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                />
              </InputGroup>
              {passwordError && (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  {passwordError}
                </p>
              )}
            </Form.Group>
            <div className="m-3">
              <span className="fw-bold">{labels.login[selectedLang].chooseLang}:</span>
              <Select
                // isMulti={false}
                isSearchable={false}
                name="Language"
                value={language}
                options={langOptions}
                onChange={(option) => {
                 setLang(option)
                 dispatch(setSelectedLanguage(option.value))
                }}
              />
            </div>
            {
              loading ? <center><Spinner animation="border" variant="dark" /></center> :
              <button type="submit" className={styles.loginBtn}>
                <Icon.BoxArrowInRight size={18} className="me-2" />
                {labels.login[selectedLang].loginButton}
              </button>
            }
          </Form>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    height: "100vh",
    width: "100vw",
  },

  loginContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "40px",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    width: "100%",
    maxWidth: "550px",
  },

  loginBtn: {
    margin: "4px px 4px 0px",
    width: "100%",
    backgroundColor: "#081225",
    color: "#F0F3F4",
    borderColor: "#081225",
    fontSize: "18px",
    "&:hover, &:focus, &:active": {
      backgroundColor: "#F0F3F4",
      color: "#081225",
      borderColor: "#081225",
      transition: "all 0.2s infinite",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "white",
      borderColor: "gray",
    },
    padding: "10px 0px",
    borderRadius: "50px",
    border: "1px solid #081225",
  },

  loginTitle: {
    color: "#081225",
    textAlign: "center",
    marginBottom: "6px",
  },

  leftSection: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    // background: `url(${backgroundImage}) no-repeat center center`,
    // backgroundSize: "cover",
    width: "100%",
    height: "100%",
  },

  rightSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },

  "@media (max-width: 992px)": {
    mainContainer: {
      position: "relative",
    },
    leftSection: {
      // background:
      //   "url('https://img.freepik.com/premium-vector/man-freelancer-sits-table-cafe-working-laptop-remote-worker-drinking-beverage-coworking-space-workplace-cafeteria-restaurant_575670-1291.jpg?size=626&ext=jpg&ga=GA1.1.1546980028.1702771200&semt=ais') no-repeat center center",
      // backgroundSize: "cover",
      display:'none',
      width: "100%",
      height: "100vh",
    },
    
    rightSection: {
      width: "100%",
      padding: "20px",
      boxSizing: "border-box",
      zIndex: "1000",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    loginContainer: {
      maxWidth: "100%",
    },
  },
});

export default Login;
