import Spinner from "react-bootstrap/Spinner";

function Spinners(size = 24, color = "black") {
  return (
    <div
      style={{
        height: "80vh",
        width: "80vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner size={size} animation="border" color={color} />
    </div>
  );
}

export default Spinners;
