import React, { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";

import { Card, Spinner } from "react-bootstrap";
// import ChartModal from './ChartModal';

import ChartDataLabels from 'chartjs-plugin-datalabels';


const MultibarChart = ({ chartLabels, chartData, stacked, chartHeading }) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (chartData !== undefined && chartData?.length > 0) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [chartData]);


  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const  handleShow = () => setShow(true);

  /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
  const [label, setLabel] = useState();
  const [dataLabel, setDataLabel] = useState();
  const [value, setValue] = useState();

  return (
    <>
      <Card style={{ height: "500px", padding: "60px 20px" }}>
        <h5 style={{textAlign:"center", marginBottom:"10px"}}>{chartHeading}</h5>
        {loader ? (
          <center>
            <Spinner />
          </center>
        ) : (
          <Chart
            type='bar'
            data={{
              labels: chartLabels,
              fill: false,
              datasets: chartData.map((row) => {
                return {
                  type: row?.type,
                  label: row?.label,
                  data: row?.data,
                  fill: true,
                  borderWidth: row?.borderWidth,
                  borderColor:row?.borderColor,
                  backgroundColor: row?.backgroundColor,
                };
              }),
            }}

            plugins={[ChartDataLabels,  {align: "end"}]}
                options={{
                  plugins: {
                    datalabels: {
                      display: true,
                      color: '#808080',
                      formatter: Math.round,
                      anchor: "end",
                      margin:'20px',
                      offset: -20,
                      align: "start",
                      font: {
                            weight: '500',
                            size:'12px'
                          }
                    }
                  },
                  legend: {
                    display: false
                  },
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    x: {
                      stacked: stacked,
                    },
                    y: {
                      stacked: stacked
                    }
                  },
                  maintainAspectRatio: false,
                  responsive: true,
              onClick: (e, element) => {
                let index = element[0].index;
                // let dataindex = element[0].datasetIndex;

                let arr = [];
                e.chart.config._config.data.datasets.map((row) => {
                  arr.push(row.data[index]);
                });

                setLabel(e.chart.data.labels[element[0].index]);
                setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
                setValue(arr[element[0].datasetIndex]);
                setShow(true)

                // if(index!==undefined && index!==""){
                //   console.log("Data set Array", e.chart.config._config.data.datasets[index].label)
                // }
              },
            }}
          />
        )}
      </Card>

      {/* <ChartModal open={show} close={() => setShow(false)} data={{ label, dataLabel, value }} /> */}
    </>
  );
};

export default MultibarChart;
