import React, { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
 
const PieChart = ({ chartLabels, chartData, stacked,chartHeading, indexAxis }) => {
    const [loader, setLoader] = useState(false);
    const [label, setLabel] = useState();
    const [dataLabel, setDataLabel] = useState();
    const [value, setValue] = useState();
    const [show, setShow] = useState(false);  

    const dColor = []
 
    const generateLabelandCount = chartLabels && Object.keys(chartLabels).map(() => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
 
        return (
            dColor.push("rgb(" + r + "," + g + "," + b + ")")
        )
    })
 
    return (
      <>
      <Row md={12}>
        <Col md={12}>
        <Card className='mx-auto' style={{ height: "350px",padding:"35px 35px"}}>
            <h5 style={{textAlign:"center", marginBottom:"10px"}}>{chartHeading}</h5>
            {loader ? (
                <center>
                    <Spinner />
                </center>
        ) :(
            <Pie
                style={{ width: '100%',alignSelf:'center' }}
                data={{
                    labels: chartLabels,
                    datasets: [{
                        data: chartData,
                        // backgroundColor: ['rgb(255, 99, 132)','rgb(85, 235, 52)','rgb(61, 52, 235)','rgb(226, 52, 235)'],
                        backgroundColor: ["#ED3833","#66D938",],
                        hoverOffset: 10
                    }]
 
                }}

                plugins={[ChartDataLabels,  {align: "center"}]}
                options={{
                  plugins: {
                    datalabels: {
                      display: true,
                      color: '#808080',
                      formatter: Math.round,
                      anchor: "center",
                      margin:'20px',
                      offset: -20,
                      align: "start",
                      font: {
                            weight: '500',
                            size:'14px'
                          }
                    }
                  },
                  legend: {
                    display: false
                  },
                  interaction: {
                    intersect: false,
                  },
                //   scales: {
                //     x: {
                //       stacked: stacked,
                //     },
                //     y: {
                //       stacked: stacked
                //     }
                //   },
                  maintainAspectRatio: false,
                  responsive: true,
              onClick: (e, element) => {
                let index = element[0].index;
                // let dataindex = element[0].datasetIndex;

                let arr = [];
                e.chart.config._config.data.datasets.map((row) => {
                  arr.push(row.data[index]);
                });

                setLabel(e.chart.data.labels[element[0].index]);
                setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
                setValue(arr[element[0].datasetIndex]);
                setShow(true)

                // if(index!==undefined && index!==""){
                //   console.log("Data set Array", e.chart.config._config.data.datasets[index].label)
                // }
              },
            }}
                 />
        )}   
        </Card>
        </Col>
      </Row> 
      </>
        
    )
}
 
export default PieChart
