import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllQuestionList } from './slice/AllQuestionSlice'
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'
import CustomTable from '../../Common/Table/CustomTable';
import { useLocation, useNavigate } from 'react-router-dom';

const ViewQuestionsList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const allQuestionDataList = useSelector((state) => state.allQuestions.AllQuestion)
    const className = location.state.class
    const subjectName = location.state.subject
    const topicName = location.state.topic

    const getAllquestion = () => {
        const payload = {
            classId: className,
            subjectId: subjectName,
            topicId: topicName
        };
        dispatch(getAllQuestionList(payload))
    }

    useEffect(() => {
        getAllquestion();
    }, [])

    // for table data
    const headerData = [
        { header: 'Sr.No', field: 'srNo' },
        { header: 'Questions', field: 'question' },
        { header: 'Action', field: 'action' },
    ];

    const questionDataList = allQuestionDataList?.data !== undefined &&
        allQuestionDataList?.data !== null &&
        allQuestionDataList?.data.length > 0 ?
        allQuestionDataList?.data.map((itm, index) => {
            return {
                srNo: index + 1,
                question: itm?.question,
                action: <span className="d-flex justify-content-center">
                    <Button className='primaryBtn w-50' onClick={() => navigate("/dashboard/singlequiz", {
                        state: { data: itm }
                    })}>
                        Start Test
                    </Button>
                </span>,
            };
        }) : []

    return (
        <div className='ViewQuestion  py-4'>
            <Container>
                <Row className='mt-3'>
                    <Col md={12}>
                        <Button className='secondaryBtn me-3' style={{ fontSize: '15px' }}
                            onClick={() => window.history.back()}
                        >
                            <Icon.ArrowLeftShort className='me-1' size={18} />
                            Back
                        </Button>
                    </Col>
                </Row>
                <hr className='w-100 mb-0' />
                <CustomTable headerData={headerData} headerRowData={questionDataList} />
            </Container>
        </div>
    )
}

export default ViewQuestionsList