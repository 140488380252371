import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loggedInUser:{},
    sessionStartTime:'',
    sessionDetails:{},
    sessionEndTime:'',
    selectedLanguage:'en',
    sessionTimer:0,
    isSessionRunnig:false
};


const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUser(state, action) {
            state.loggedInUser = action.payload
        },
        setSessionStartTime(state,action){
            state.sessionStartTime = action.payload
            state.isSessionRunnig = true
        },
        setSessionEndTime(state,action){
            state.sessionEndTime = action.payload
            state.isSessionRunnig = false
        },
        setUpdateTimer(state,action){
            state.sessionTimer = action.payload
        },
        emptySession(state,action){
            state.sessionStartTime = ''
            state.sessionEndTime = ''
            state.sessionDetails = {}
            state.sessionTimer = 0
        },
        setSelectedLanguage(state,action){
            state.selectedLanguage = action.payload
        },
        setSessionDetails(state,action){
            state.sessionDetails = action.payload
        }
    },
});


export const { setUser,setSessionStartTime,setSessionEndTime,emptySession,setSelectedLanguage,setUpdateTimer,setSessionDetails } = UserSlice.actions;
export default UserSlice.reducer;