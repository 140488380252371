import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ApiCalling } from "../../Services/pool";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import labels from "../../config/labels.json";
import { setEndQuestion } from "../../Feature/Sidebar/Questionslice";

const socket = io(ApiCalling.socketUrl.baseUrl);

const SessionWiseQueAnsList = () => {
  const [questions, setQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [subject, setSubject] = useState();
  const [topic, setTopic] = useState();
  const [classData, setClassData] = useState();
  const [studentCount, setstudentCount] = useState([]);
  const [questionId, setquestionId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const user = useSelector((state) => state.userData.loggedInUser);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const newChildWindowRef = useSelector(
    (state) => state.question.questionChildRef
  );

  useEffect(() => {
    getSocketData();
    // socket.off("questionRes");
  }, []);

  const getSocketData = (questionId) => {
    console.log("Socket Calling");
    socket.emit("getAnsResponce", {
      classId: user.classId,
      schoolId: user.schoolId,
    });
    console.log("data", {
      classId: user.classId,
      schoolId: user.schoolId,
    });
    socket.on("questionRes", (data) => {
      console.log("Socket response", data);
      setSubject(data[0].subjectId);
      setClassData(data[0].classId);
      setTopic(data[0].topicId);
      setquestionId(data[0]?.questionId);
      setstudentCount(data[data.length - 1]?.studentsCount);
      setQuestions(data);
    });
  };

  const Findgreen = (question) => {
    return (
      <div
        className="d-flex mt-4 "
        style={{
          flexDirection: "column",
          border: "1px solid #D8D7D7",
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            // alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {studentCount?.length > 0 ? (
            studentCount?.map((row, index) => {
              let Isgreen = question.filter((item) => {
                if (item.deviceId == row.deviceId) {
                  return true;
                } else {
                  return false;
                }
              });
              Isgreen = Isgreen.length == 0 ? false : true;

              return (
                <div
                  key={index}
                  className="liveStudent"
                  style={{
                    color: Isgreen ? "white" : "black",
                    backgroundColor: Isgreen ? "#048B02" : "#DDDDDD",
                  }}
                >
                  {row.rollNo} - {row.name.split(" ")[0]}
                </div>
              );
            })
          ) : (
            <center>
              <h4>Waiting for Answers....</h4>
              <br />
              <Spinner />
            </center>
          )}
        </div>
      </div>
    );
  };

  const endTest = async () => {
    let link =
      user?.role == "studioteacher"
        ? "/qsa/endQuestion"
        : "/qsa/endQuestion/classteacher";
    const payload = { QuestionId: questionId };
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      closeExtendedScreenFromDifferentPage()
      navigate("/dashboard/questionAnalysis", {
        state: {
          data: {
            questionId: questionId,
            questionType: questions[0].questionType,
            question: questions[0].question,
          },
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeExtendedScreenFromDifferentPage = () => {
    dispatch(setEndQuestion({}));
    if (window.screen.isExtended && newChildWindowRef) {
      newChildWindowRef.current.close();
    } else {
      console.error("Parent window not found or closed.");
    }
  };

  const getUniqueIds = (data) => {
    const uniqueDeviceIds = new Set();

    // Filter the array to get unique elements based on deviceId
    const uniqueElements = data.filter((item) => {
      const deviceId = item.deviceId;

      // Check if deviceId is not in the set
      if (!uniqueDeviceIds.has(deviceId)) {
        // Add deviceId to the set
        uniqueDeviceIds.add(deviceId);

        // Return true to include the item in the result
        return true;
      }

      // Return false to exclude duplicates
      return false;
    });

    return uniqueElements;
  };

  return (
    <Container className="kbc py-5 d-flex" style={{ flexDirection: "column" }}>
      <Row className="d-flex justify-content-between align-items-center">
        {questions.length > 1 ? (
          <Col className="d-flex justify-content-between align-items-center flex-wrap">
            {/* <Button
              className="secondaryBtn me-3"
              style={{ fontSize: "15px" }}
              onClick={() => window.history.back()}
            >
              <Icon.ArrowLeftShort className="me-1" size={18} />
              Back
            </Button> */}
            <div>
              <p>Subject Name </p>
              <div
                style={{
                  width: "282.84px",
                  height: "54px",
                  border: "1px solid #D8D7D7",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6 className="fw-bold" style={{ alignSelf: "center" }}>
                  {subject}
                </h6>
              </div>
            </div>
            <div>
              <p>Class Name </p>
              <div
                style={{
                  width: "282.84px",
                  height: "54px",
                  border: "1px solid #D8D7D7",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6 className="fw-bold" style={{ alignSelf: "center" }}>
                  {classData}
                </h6>
              </div>
            </div>
            <div>
              <p>Topic Name </p>
              <div
                style={{
                  width: "282.84px",
                  height: "54px",
                  border: "1px solid #D8D7D7",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6 className="fw-bold" style={{ alignSelf: "center" }}>
                  {topic}
                </h6>
              </div>
            </div>
          </Col>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <h1>
              {selectedLang === "odia"
                ? "ବର୍ତ୍ତମାନ ପାଇଁ କ Live ଣସି ଲାଇଭ୍ ପୋଲ୍ ଅଧିବେଶନ ଉପଲବ୍ଧ ନାହିଁ |"
                : "No Live Poll Sessions available for now"}
            </h1>
          </div>
        )}
      </Row>
      <Row className="d-flex justify-content-center align-items-center">
        {loader ? (
          <center>
            <Spinner />
          </center>
        ) : (
          <div className="liveSession">
            {questions.length > 1 && (
              <p style={{ fontSize: "20px" }}>Questions</p>
            )}
            {questions.map((row, index) => {
              if (index !== questions.length - 1) {
                return (
                  <div key={index} style={{ marginTop: "3%" }}>
                    <p
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {row.question}
                    </p>
                    <div style={{ marginTop: "1%" }}>
                      <p>
                        No of students in the class - {studentCount?.length}
                      </p>
                      <p>
                        No of students attend the test -{" "}
                        {getUniqueIds(row.questionAns).length}
                      </p>
                      <p>
                        No of students who did not attend the test -
                        {studentCount?.length -
                          getUniqueIds(row.questionAns)?.length}
                      </p>
                    </div>
                    {Findgreen(row.questionAns)}
                  </div>
                );
              }
            })}
          </div>
        )}
      </Row>
      {questions.length > 1 && (
        <Row className="py-5 d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-end align-items-end">
            <Button
              className="primaryBtn"
              onClick={() => {
                endTest();
              }}
            >
              End Test
            </Button>
          </div>
        </Row>
      )}
    </Container>
  );
};

export default SessionWiseQueAnsList;
