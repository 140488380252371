import axios from 'axios'
import React, { useEffect, useState } from 'react';
import { ApiCalling } from '../../Services/pool';

const useGetAllClass = () => {

    const [allclassoptions, setAllClassOptions] = useState([])

    useEffect(() => {
        getclassList()
    }, [])

    const getclassList = async () => {
        try {
            await fetch(`${ApiCalling.base_url_v1}${ApiCalling.paths.classList}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJneHBlcnRuZXdAZ21haWwuY29tIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6Imd4cGVydG5ld0BnbWFpbC5jb20iLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6Imd4cGVydG5ld0BnbWFpbC5jb20iLCJ1bmlxdWVfbmFtZSI6Imd4cGVydG5ld0BnbWFpbC5jb20iLCJzdWIiOiJneHBlcnRuZXdAZ21haWwuY29tIiwianRpIjoiMWJkOGVmYzktMjI0NS00MmM2LWFmYWEtMmIyNzIyZmU0YjBlIiwiZXhwIjoxNzM4MzkyNDU4LCJpc3MiOiJodHRwczovL3Jpb3guYXp1cmV3ZWJzaXRlcy5uZXQiLCJhdWQiOiJodHRwczovL3Jpb3guYXp1cmV3ZWJzaXRlcy5uZXQifQ.v0QvfiDVUZ1U36MKbSZjwzR4P80QzK1bvOZCnTemmWo'
                },
                // body: JSON.stringify({})

            }).then((res) => res.json())
                .then((res) => {
                    const filterOption = (res?.Entities)?.length > 0 && (res?.Entities)?.map((itm) => {
                        return ({ ...itm,value: itm.Id, label: itm.Name })
                    })

                    if (filterOption?.length > 0) {
                        setAllClassOptions(filterOption)
                    }
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        } catch (error) {
            console.log("Error", error)
        }
    }
    return allclassoptions
}

export default useGetAllClass
