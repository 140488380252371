import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  InputGroup,
  Modal,
  Card,
} from "react-bootstrap";
import Select from "react-select";
import { ApiCalling } from "../../Services/pool";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { showToastSuccess } from "../Utilities/toastutils";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import CustomTable from "../../Common/Table/CustomTable";
import { toast } from "react-toastify";
import labels from "../../config/labels.json";

const StudentList = () => {
  const [studentList, setStudentList] = useState([]);
  const [updateStuent, setUpdateStudent] = useState();
  const [enteredDevice, setEnteredDevice] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deviceIdError, setDeviceIdError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [filterList, setFilterList] = useState();
  const [searchStudent, setSearchStudent] = useState();


  const user = useSelector((state) => state.userData.loggedInUser);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);


  const getStudentList = async () => {
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.studentList}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          classId: user.classId,
          schoolId: user.schoolId,
        }),
      }
    );

    const responseData = await response.json();
    setStudentList(responseData.data);
    setFilterList(responseData.data)
  };

  const headerData = [
    { header: labels.studentList[selectedLang].rollNo, field: "rollNo" },
    { header: labels.studentList[selectedLang].studentName, field: "name" },
    { header: labels.studentList[selectedLang].className, field: "className" },
    { header: labels.studentList[selectedLang].deviceId, field: "deviceId" },
    // { header: "Created Date", field: "createdDate" },
    { header: labels.studentList[selectedLang].action, field: "actions" },
  ];

  const studentDataList =
    filterList !== undefined && filterList !== null && filterList?.length > 0
      ? filterList?.map((itm, index) => {
          return {
            rollNo: itm.rollNo,
            name: itm.name,
            className: itm?.className,
            deviceId: itm?.deviceId,
            actions: (
              <span className="d-flex justify-content-center">
                <button
                  className="primaryBtn "
                  onClick={() => openDeviceModal(itm)}
                >
                  {labels.studentList[selectedLang].UpdateStudentDevice}
                </button>
              </span>
            ),
          };
        })
      : [];

  const openDeviceModal = (itm) => {
    console.log("Upadte Device", itm);
    setUpdateStudent(itm);
    setShowModal(true);
  };

  useEffect(() => {
    getStudentList();
  }, []);

  const updateDeviceId = async () => {
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.updateStudent}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          studentId: updateStuent.studentId,
          deviceId: enteredDevice ? enteredDevice : updateStuent.deviceId,
        }),
      }
    );

    const responseData = await response.json();
    if (responseData.status === 200) {
      toast.success(responseData.message);
      setShowModal(false);
      setEnteredDevice("");
      getStudentList();
    }
  };

  const getSearchStudentByName = (searchValue) => {
    let filter = studentList.filter((row) => {
      return (
        row.name
          .toLowerCase()
          .startsWith(searchValue.toLowerCase().trim()) ||
        row.name.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
    });

    setFilterList(filter);
  };

  const searchByStartEndValidate = () => {
    let isValid = true;
    if (!startDate) {
      isValid = false;
      setStartDateErr(true);
    } else if (!endDate) {
      isValid = false;
      setEndDateErr(true);
    }
    return isValid;
  };

  const searchByDate = () => {
    if (searchByStartEndValidate()) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);

      let filter = studentList.filter((row) => {
        const createdAt = new Date(row.createdAt);
        return createdAt >= start && createdAt <= end;
      });

      console.log("Date Filter", filter, startDate, endDate);
      setFilterList(filter);
    }
  };

  return (
    <div>
      <Row className="justify-content-center pt-3">
        <Col md={4}>
          <Card className="searchCard">
            <Form.Label className="searchLabel">{labels.studentList[selectedLang].searchByName}</Form.Label>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="">
              {labels.studentList[selectedLang].searchName}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={searchStudent}
                name="question"
                onChange={(e) => {
                  getSearchStudentByName(e.target.value);
                }}
                placeholder={labels.studentList[selectedLang].enterStudent}
              />
            </Form.Group>
          </Card>
        </Col>
        {/* <Col md={6}>
          <Card className="searchCard">
            <Form.Label className="searchLabel">Search By Date</Form.Label>
            <Row className="d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                    setEndDate("");
                    e.target.value.length > 0 && setStartDateErr(false);
                  }}
                />
                {startDateErr ? (
                  <p className="errMsg">Please select Start Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  min={startDate ? startDate : ""}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                    e.target.value.length > 0 && setEndDateErr(false);
                  }}
                />
                {endDateErr ? (
                  <p className="errMsg">Please select End Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={2} className="mt-4">
                <Button className="btn btn-submit" onClick={searchByDate}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
          </Card>
        </Col> */}
      </Row>
      {studentList?.length > 0 ? (
        <CustomTable headerData={headerData} headerRowData={studentDataList} />
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <h3>{labels.studentList[selectedLang].nodatafound}</h3>
        </div>
      )}
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setEnteredDevice("");
        }}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          style={{
            color: "black",
            width: "100%",
            height: "50px",
            // backgroundColor: "#F9D65F",
          }}
        >
          <Modal.Title style={{ fontWeight: "bold" }}>
          {labels.studentList[selectedLang].updateStudent} -{" "}
            {updateStuent && updateStuent.rollNo}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body size="lg">
          <Row className="mb-4 m-3">
            <Col sm={12} md={12}>
              <Form.Group>
                <Form.Label className="mt-3 fw-bold">
                {labels.studentList[selectedLang].deviceId}
                  <span className="requiredfield"> *</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder={
                      updateStuent ? updateStuent.deviceId : labels.studentList[selectedLang].enterDeviceId
                    }
                    className="input-field project-inputgroup"
                    name="Device Id"
                    value={enteredDevice}
                    onChange={(e) => {
                      if (
                        /^\d*$/.test(e.target.value) &&
                        e.target.value.trim()
                      ) {
                        setEnteredDevice(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setEnteredDevice(e.target.value);
                      }
                      enteredDevice && enteredDevice.length >= 0
                        ? setDeviceIdError(false)
                        : setDeviceIdError(true);
                    }}
                  />
                </InputGroup>
                {deviceIdError ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    *{labels.studentList[selectedLang].required}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="text-center mt-4">
          <Button
            className="primaryBtn "
            onClick={(e) => {
              updateDeviceId(e);
            }}
          >
            <Icon.PersonAdd className="me-2" />{labels.studentList[selectedLang].enterDeviceId}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StudentList;
