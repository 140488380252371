// TimerDisplay.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEndQuestion } from "../../Feature/Sidebar/Questionslice";
import labels from "../../config/labels.json";
import { ApiCalling } from "../../Services/pool";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const useQuestionStart = () => {
  const dispatch = useDispatch();
  const isRunning = useSelector((state) => state.question.isQuestionStart);
  const questionDetails = useSelector((state) => state.question.TestQuestion);
  const user = useSelector((state) => state.userData.loggedInUser);
  const newChildWindowRef = useSelector(
    (state) => state.question.questionChildRef
  );
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const stopQuestion = async () => {
    console.log("Question Details", isRunning, questionDetails);
    setLoader(true);
    let link =
      user?.role == "studioteacher"
        ? "/qsa/endQuestion"
        : "/qsa/endQuestion/classteacher";
    const payload = { QuestionId: questionDetails.questionId };
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      dispatch(setEndQuestion({}));
      setLoader(false);
      toast.success("Test End");
      navigate("/dashboard/questionAnalysis", {
        state: { data: questionDetails },
      });
      closeExtendedScreenFromDifferentPage()
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeExtendedScreenFromDifferentPage = () => {
    if (window.screen.isExtended && newChildWindowRef) {
      newChildWindowRef.current.close();
    } else {
      console.error("Parent window not found or closed.");
    }
  };

  return (
    isRunning && (
      <div className="m-2 d-flex flex-column">
        <h5>Live Question On going !!</h5>
        <div>
          {loader ? (
            <Spinner />
          ) : (
            <button
              className="sessionLive w-80"
              onClick={() => {
                stopQuestion();
              }}
            >
              End Question
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default useQuestionStart;
