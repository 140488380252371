import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiCalling } from '../../../Services/pool';

const initialState = {
    AllQuestion: [],
    loading: 'idle',
};

export const getAllQuestionList = createAsyncThunk("getAllQuestionListPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(`${ApiCalling.base_url_v1}${ApiCalling.paths.getQuestionsList}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)


const AllTaskSlice = createSlice({
    name: 'allQuestionlist',
    initialState,
    extraReducers: {
        [getAllQuestionList.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllQuestionList.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllQuestionList.fulfilled]: (s, a) => {
            s.AllQuestion = a.payload;
            s.loading = 'success';
        },

    },
});

export default AllTaskSlice.reducer;
