import React, { useEffect, useState } from "react";
import CustomTable from "../../Common/Table/CustomTable";
import { ApiCalling } from "../../Services/pool";
import {
  Tab,
  Nav,
  Row,
  Col,
  Button,
  Card,
  Container,
  Spinner,
} from "react-bootstrap";
import MultibarChart from "../../Common/Charts/MultibarChart";
import PaiChart from "../../Common/Charts/PieChart";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import labels from "../../config/labels.json";
import * as Icon from "react-bootstrap-icons";
import Chartjs from "../../Common/Charts/Chartjs";
import generatePdfFromCharts from "../Utilities/ExportPDF";

const Dashboard = () => {
  const navigate = useNavigate();

  const [key, setKey] = useState("Dashboard");
  const [studentAnswer, setStudentAnswer] = useState([]);

  const [Answeroptions, setAnswerOptions] = useState([]);
  const [Answercounts, setAnswerCounts] = useState([]);
  const [studioQuestion, setStudioQuestion] = useState([]);
  const [studioCount, setStudioCount] = useState([]);

  const [label, setLable] = useState([]);
  const [value, setValue] = useState([]);

  const [graphLabels, setGraphLabels] = useState([]);
  const user = useSelector((state) => state.userData.loggedInUser);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getStudentAnswers();
    getAnswerPieChart();
    getStudioGraph();
    // getStudentWiseAccuracyAndDuration();
  }, []);

  // for table data
  const headerData = [
    { header: labels.dashboard[selectedLang].srNo, field: "srNo" },
    { header: labels.dashboard[selectedLang].question, field: "question" },
    {
      header: labels.dashboard[selectedLang].answerCount,
      field: "answerCount",
    },
    {
      header: labels.dashboard[selectedLang].viewAnalytics,
      field: "viewAnalytics",
    },
  ];

  const getStudioGraph = async () => {
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.studioGraph}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const responseData = await response.json();

    let labelArray = [];
    let correctArray = [];
    let wrongArray = [];

    responseData[0].questionWiseRes.map((row, index) => {
      labelArray.push(row.question);
      correctArray.push(row.correctCount);
      wrongArray.push(row.wrongCount);
    });

    let dataArray = [];
    dataArray.push({
      type: "bar",
      label: "Correct response",
      fill: true,
      data: correctArray,
      backgroundColor: "rgba(17,154,5)",
    });

    dataArray.push({
      type: "bar",
      label: "Wrong response",
      fill: true,
      data: wrongArray,
      backgroundColor: "rgba(224, 119,75)",
    });

    setStudioQuestion(labelArray);
    setStudioCount(dataArray);
  };

  const getStudentAnswers = async () => {
    setLoader(true);
    var link = `${ApiCalling.base_url_v1}${ApiCalling.paths.getStudentAnswers}?classId=${user.classId}`;
    const response = await fetch(link, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(data)
    });
    const responseData = await response.json();
    if (responseData.length > 0) {
      setStudentAnswer(responseData);
      setLoader(false);
      let array1 = [];
      for (let index = 0; index < responseData.length; index++) {
        array1.push(responseData[index].ansCount);
      }

      let array2 = [];
      for (let index = 0; index < responseData.length; index++) {
        console.log("first", responseData);
        array2.push(`Q No - ${index + 1}`);
      }

      let dataArray = [];
      dataArray.push({
        type: "bar",
        label: "Options",
        fill: true,
        data: array1,
        backgroundColor: "rgba(17,141,255,255)",
      });

      setAnswerOptions(array2);
      setAnswerCounts(dataArray);
    } else {
      setLoader(false);
    }
  };

  const questionDataList =
    studentAnswer !== undefined &&
    studentAnswer !== null &&
    studentAnswer.length > 0
      ? studentAnswer.map((itm, index) => {
          return {
            srNo: index + 1,
            question: itm?.question,
            answerCount: itm?.ansCount,
            viewAnalytics: (
              <span className="d-flex ">
                <button
                  disabled={!itm?.ansCount > 0}
                  className="primaryBtn w-50"
                  onClick={() =>
                    navigate("/dashboard/questionAnalysis", {
                      state: { data: itm },
                    })
                  }
                >
                  {labels.dashboard[selectedLang].view}
                </button>
              </span>
            ),
          };
        })
      : [];

  const getStudentWiseAccuracyAndDuration = async () => {
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.getStudentWiseGraph}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const responseData = await response.json();
  };

  const getAnswerPieChart = async () => {
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.getAnswerPieChart}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const responseData = await response.json();
    setLable(responseData?.labels);
    setValue(responseData?.values);
  };

  return (
    <div className="Dashboard">
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <Row className="justify-content-end">
          <Col
            md="auto"
            lg="max-content"
            className="d-flex justify-content-center"
          >
            <Nav
              variant="pills"
              className="flex-column flex-md-row justify-content-center w-100"
            >
              <Nav.Item>
                <Nav.Link eventKey="Dashboard">
                  {key === "Dashboard" ? (
                    <Icon.GridFill className="me-2" />
                  ) : (
                    <Icon.Grid className="me-2" />
                  )}
                  {labels.dashboard[selectedLang].dashboard}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Analytics">
                  {key === "Analytics" ? (
                    <Icon.BarChartFill className="me-2" />
                  ) : (
                    <Icon.BarChart className="me-2" />
                  )}
                  {labels.dashboard[selectedLang].analytics}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>

        <Tab.Content className="mt-0">
          <Tab.Pane eventKey="Dashboard">
            <div className="mb-5">
              <div className="mb-4">
                <u>
                  <h4 className="text-center" style={{ fontWeight: "600" }}>
                    {labels.dashboard[selectedLang].questionListandCount}
                  </h4>
                </u>
              </div>

              <div>
                {loader ? (
                  <center>
                    <Spinner variant="dark" />
                  </center>
                ) : (
                  questionDataList &&
                  questionDataList.length > 0 && (
                    <CustomTable
                      headerData={headerData}
                      headerRowData={questionDataList}
                    />
                  )
                )}
              </div>

              <div className="mt-4">
                <div style={{ height: "10%", margin: "auto" }}>
                  {!loader &&
                    user.role === "studioteacher" &&
                    studioQuestion.length > 0 && (
                      <MultibarChart
                        chartLabels={studioQuestion}
                        chartData={studioCount}
                        stacked={true}
                        chartHeading={
                          labels.dashboard[selectedLang].totalNumberOfResponded
                        }
                      />
                    )}
                </div>
                {!loader && (
                  <Button
                    className="primaryBtn ms-auto mb-4 mt-2"
                    onClick={() =>
                      generatePdfFromCharts(
                        "totalStudentsReponded",
                        "Student_Response_Report"
                      )
                    }
                  >
                    <Icon.FiletypePdf size={18} className="me-2" />
                    Export Reports
                  </Button>
                )}
                <div
                  style={{ height: "10%", margin: "auto" }}
                  id="totalStudentsReponded"
                >
                  {!loader &&
                    user.role === "classteacher" &&
                    Answeroptions &&
                    Answercounts && (
                      <>
                        <Chartjs
                          chartLabels={Answeroptions}
                          chartData={Answercounts}
                          stacked={true}
                          chartHeading="Total number of student responded on question"
                          height="500px"
                          isModal={true}
                        />
                      </>
                    )}
                </div>
                <div style={{ height: "10%", margin: "auto" }} className="mt-5">
                  {!loader &&
                    user.role === "classteacher" &&
                    label &&
                    label.length > 0 &&
                    value.length > 0 && (
                      <PaiChart
                        chartLabels={label}
                        chartData={value}
                        chartHeading={
                          labels.dashboard[selectedLang].answerPieChart
                        }
                        indexAxis
                      />
                    )}
                </div>
              </div>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="Analytics">
            {user?.role === "studioteacher" && (
              <iframe
                title="Admin Analytics"
                width="100%"
                height="600"
                src="https://odisha.analytics.foxberry.live/admin"
                allowFullScreen="true"
              ></iframe>
            )}

            {user.role === "classteacher" && (
              <iframe
                title="Teacher Analytics"
                width="100%"
                height="600"
                src="https://odisha.analytics.foxberry.live/teacher/14/Grade%206/GHANTAPADA%20HIGH%20SCHOOL./"
                frameBorder="0"
                allowFullScreen="true"
              ></iframe>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Dashboard;

{
  /* <BarChart chartLabels={studentNames} chartLabel="Responce Time" ColorArray='rgb(18, 103, 207, 0.4)' BorderArray='#073c7c'
                    chartData={testDuration} chartHeading="Student Wise Test responce Duration" indexAxis = "x"/> */
}
