import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  showToastError,
  showToastWarning,
} from "../../Components/Utilities/toastutils";
import { toast } from "react-toastify";
import { ApiCalling } from "../../Services/pool";
import { useSelector } from "react-redux";

const SingleQueQuizeSchool = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [locationData,setLocationData] = useState()
  const params = new URLSearchParams(location.search)  

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [timer, setTimer] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [answerColors, setAnswerColors] = useState();
  const toastDisplayedRef = useRef(false);

  // const handleOptionClick = (index) => {
  //     setSelectedOption(index);
  // };
  const user = useSelector((state) => state.userData.loggedInUser);
  const handleOptionClick = (index) => {
    if (isPlaying && selectedOption === null) {
      setSelectedOption(index);

      // Check if the selected option is correct
      const isCorrect = locationData.rightAns === locationData.options[index];

      // Update the color immediately
      setAnswerColors((prevColors) => {
        const newColors = [...prevColors];
        newColors[index] = isCorrect ? "correct" : "incorrect";
        return newColors;
      });

      // If you want to perform additional actions, do them here
      setTimeout(() => {
        // Your additional action here
      }, 1000); // Adjust the delay as needed
    }
  };

  const handlerToastMessage = () => {
    if (!toastDisplayedRef.current) {
      showToastWarning("Time is up!");
      setIsPlaying(false);
      toastDisplayedRef.current = true;

      // Navigate after 2 seconds
      setTimeout(() => {
        navigate("/dashboard/questionAnalysis", {
          state: { data: locationData },
        });
        toast.dismiss();
      }, 2000);
    }
  };

  
  useEffect(() => {
    if(location.state?.data !== undefined){
      setLocationData(location.state?.data)
    }else{
      console.log("dkuasdas",JSON.parse(params.get('question')))
      let data = JSON.parse(params.get('question'))
      setLocationData(data)
      setAnswerColors(Array(data?.options.length).fill(""))
      console.log("Show Answer",data.showAnswer)
      setShowCorrectAnswer(data.showAnswer)
    } 
  }, []);

  useEffect( () => {
    if(locationData){
    startTest();
    }
  },[locationData] )

  useEffect(() => {
    let interval;

    if (isPlaying && locationData !== undefined) {
      console.log(locationData)
      setTimer(parseInt(locationData?.displayTime, 10));
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            setIsPlaying(false);
            clearInterval(interval);
            handlerToastMessage();
            return 0;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying, locationData]);

  useEffect(() => {
    if (currentQuestionIndex < questions.length - 1 && !isPlaying && locationData !== undefined) {
      const timer = setTimeout(() => {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setIsPlaying(true);
      }, 1000 * parseInt(locationData?.displayTime, 10));

      return () => clearTimeout(timer);
    }
  }, [currentQuestionIndex, questions, locationData, isPlaying]);

  useEffect(() => {
    if (timer === 1) {
      // endTest();
    }
  }, [timer]);

  const startTest = async () => {
    const payload = { QuestionId: locationData.questionId };
    let link =
      user?.role == "studioteacher"
        ? "/qsa/startQuestion"
        : "/qsa/startQuestion/classteacher";
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const endTest = async () => {
    let link =
      user?.role == "studioteacher"
        ? "/qsa/endQuestion"
        : "/qsa/endQuestion/classteacher";
    const payload = { QuestionId: locationData.questionId };
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      toast.success("Test End");
      console.log("end responseData", responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const HandleEndTest = () => {
    endTest();
    setTimeout(() => {
      navigate("/dashboard/questionAnalysis", {
        state: { data: locationData },
      });
    }, 1000);
  };

  return (
    <>
      <div className="kbc py-5">
        <Container>
          {/* Object.keys(locationData[currentQuestionIndex]).includes("displayTime") && */}
          {locationData !== undefined && locationData.displayTime ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col className="d-flex justify-content-center align-items-center">
                <div className="p-3 d-flex flex-column align-items-center">
                  <div className="timerContainer">
                    <p>{timer}</p>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div style={{ marginTop: "8%" }}></div>
          )}

          <Row className="justify-content-center gy-4 mt-3">
            <Col xs={12}>
              <div className="question">
                <h5>{locationData?.question}</h5>
              </div>
            </Col>

            {locationData?.options &&
              locationData?.options.map((option, index) => (
                <Col md={5} key={index} style={{ cursor: "pointer" }}>
                  <div
                    className={`answer ${
                      showCorrectAnswer &&
                       locationData.rightAns === locationData.options[index]
                         ? "correct" : ""
                     }`}
                    onClick={() => handleOptionClick(index)}
                  >
                    <h5>{option}</h5>
                  </div>
                </Col>
              ))}
          </Row>

          <Row md={12} className="mx-3" style={{ marginTop: "10%" }}>
            <Col className="d-flex justify-content-end">
              {/* <button
                className="primaryBtn p-3"
                onClick={() => {
                  HandleEndTest();
                }}
              >
                End test
              </button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SingleQueQuizeSchool;
