import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ApiCalling } from "../../Services/pool";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";

const socket = io(ApiCalling.socketUrl.baseUrl);

const HandRaise = () => {
  const [studentCount, setstudentCount] = useState([]);
  const [handRaiseData, setHandRaiseData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [subject, setSubject] = useState();
  const [topic, setTopic] = useState();
  const [classData, setClassData] = useState();
  const navigate = useNavigate();
  const [handRaiseStarted, setHandRaiseStarted] = useState(false);
  const user = useSelector((state) => state.userData.loggedInUser);

  useEffect(() => {
    if(handRaiseStarted){
      getSocketData();
    }
  }, [handRaiseStarted]);

  const getSocketData = () => {
    console.log("Get HandRaise")
    // setLoader(true);
    socket.on("handraiseRes", (data) => {
      if (data && data.studentsCount.length > 0) {
        setClassData(user.classId);
        setSubject(user.subjectId[0]);
        setHandRaiseData(data.haindResponse);
        setstudentCount(data.studentsCount);
        // setLoader(false);
      }
    });
  };

  const Findgreen = () => {
    return (
      <div
        className="d-flex mt-4 "
        style={{
          flexDirection: "column",
          border: "1px solid #D8D7D7",
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            // alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {studentCount?.length > 0 ? (
            studentCount?.map((row, index) => {
              let Isgreen = handRaiseData.filter((item) => {
                let DeviceId =
                  row.deviceId.length === 2
                    ? row.deviceId
                    : row.deviceId.padStart(2, "0");
                if (item.deviceId == DeviceId) {
                  return true;
                } else {
                  return false;
                }
              });
              //   console.log(Isgreen)
              Isgreen = Isgreen.length == 0 ? false : true;

              return (
                <div
                  key={index}
                  className="liveStudent"
                  style={{
                    color: Isgreen ? "white" : "black",
                    backgroundColor: Isgreen ? "#048B02" : "#DDDDDD",
                  }}
                >
                  {row.rollNo} - {row.name.split(" ")[0]}
                </div>
              );
            })
          ) : (
            <center>
              <h4>Waiting for Answers....</h4>
              <br />
              <Spinner />
            </center>
          )}
        </div>
      </div>
    );
  };

  const endHandRaise = async () => {
    const payload = {
      classId: user.classId,
      schoolId: user.schoolId,
      topicId: "",
      sessionId: "",
      flag: "end",
    };
    try {
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.endHandRaise}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
      console.log(response);

      if (response.status === 200) {
        setstudentCount([]);
        setLoader(false);
        setHandRaiseStarted(false)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const startHandRaise = async () => {
    let payload = {
      classId: user.classId,
      sessionId: "",
      flag: "start",
      schoolId: user.schoolId,
    };

    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.startHandRaise}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    if (response.status === 200) {
      setHandRaiseStarted(true);
    }
  };

  return (
    <Container className="kbc py-5 d-flex" style={{ flexDirection: "column" }}>
      {!handRaiseStarted && (
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={3}>
            <Button className="primaryBtn" onClick={() => startHandRaise()}>
              <Icon.HandIndexThumbFill className="me-2" /> Start HandRaise
            </Button>
          </Col>
        </Row>
      )}
      <Row className="d-flex justify-content-center align-items-center">
        {loader ? (
          <center>
            <Spinner />
          </center>
        ) : (
          <div className="liveSession">
            {studentCount.length > 0 && (
              <div>
                <div>
                  <p className="font-weight-bold"> Class Id - {classData}</p>
                  <p className="font-weight-bold"> Subject Id - {subject}</p>
                  <p className="font-weight-bold">
                    Total students in the class - {studentCount?.length}
                  </p>
                  <p className="font-weight-bold">
                    No of students who raised hands - {handRaiseData.length}
                  </p>
                </div>
                {Findgreen()}
              </div>
            )}
          </div>
        )}
        {studentCount.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <h1>No Hand Raises available for now</h1>
          </div>
        )}
      </Row>
      {studentCount.length > 0 && (
        <Row className="py-5 d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-end align-items-end">
            <Button
              className="primaryBtn"
              onClick={() => {
                endHandRaise();
              }}
            >
              End Hand Raise
            </Button>
          </div>
        </Row>
      )}
    </Container>
  );
};

export default HandRaise;
