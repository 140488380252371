import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
export default function ShareResult() {

  const [answeByQuestion,setAnsweByQuestion] = useState([])

  const currentUrl = window.location.href;
  // Use URLSearchParams to parse the query parameters
  const urlParams = new URLSearchParams(currentUrl.split("?")[1]);
  const data = JSON.parse(urlParams.get('data'))

  console.log(data)
  
  return (
    <div className="py-4">
      <Container>
        {/* <Row style={{ marginLeft: "30px", marginBottom: "20px" }}>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center"
          >
            <Button
              className="secondaryBtn me-3"
              style={{ fontSize: "15px" }}
              onClick={() => window.history.back()}
            >
              <Icon.ArrowLeftShort className="me-1" size={18} />
              Back
            </Button>
          </Col>
        </Row> */}
        { data && (
          <div
            className="d-flex justify-content-evenly"
            style={{
              width: "80%",
              margin: "auto",
              marginBottom: "2%",
              height: "50vh",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid black",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                width: "30%",
              }}
            >
              <span className="">No of student responded</span>
              <span className="mt-2 fw-bold fs-4">
                {data.responded}/{data.studentCount}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid black",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                width: "30%",
              }}
            >
              <span className="">Correct Answer</span>
              <span className="mt-2 fw-bold fs-4">
                {(
                  ( data.correctAnswer /
                    data.responded) *
                  100
                )?.toFixed(2)}
                %
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid black",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                width: "30%",
              }}
            >
              <span className="">Wrong Answer</span>
              <span className="mt-2 fw-bold fs-4">
                {(
                  ( (data.responded - data.correctAnswer) /
                    data.responded) *
                  100
                )?.toFixed(2)}
                %
              </span>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
