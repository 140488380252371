import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  showToastError,
  showToastWarning,
} from "../../Components/Utilities/toastutils";
import { ToastContainer, toast } from "react-toastify";
import { ApiCalling } from "../../Services/pool";
import { useDispatch, useSelector } from "react-redux";
import labels from "../../config/labels.json";
import {
  setEndQuestion,
  setStartQuestion,
} from "../../Feature/Sidebar/Questionslice";

const SingleQueQuiz = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [timer, setTimer] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [locationData, setLocationData] = useState();
  const [answerColors, setAnswerColors] = useState();
  const toastDisplayedRef = useRef(false);
  const [showAns, setShowAns] = useState(false);
  const params = new URLSearchParams(location.search);
  const [loader, setLoader] = useState(false);
  const [endLoader, setEndLoader] = useState(false);

  const dispatch = useDispatch();

  // const handleOptionClick = (index) => {
  //     setSelectedOption(index);
  // };
  const user = useSelector((state) => state.userData.loggedInUser);
  const sessionDetails = useSelector((state) => state.userData.sessionDetails);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const newChildWindowRef = useSelector(
    (state) => state.question.questionChildRef
  );

  const handleOptionClick = (index) => {
    if (isPlaying && selectedOption === null) {
      setSelectedOption(index);

      // Check if the selected option is correct
      const isCorrect = locationData.rightAns === locationData.options[index];

      // Update the color immediately
      setAnswerColors((prevColors) => {
        const newColors = [...prevColors];
        newColors[index] = isCorrect ? "correct" : "incorrect";
        return newColors;
      });

      // If you want to perform additional actions, do them here
      setTimeout(() => {
        // Your additional action here
      }, 1000); // Adjust the delay as needed
    }
  };

  const handlerToastMessage = () => {
    if (!toastDisplayedRef.current) {
      showToastWarning("Time is up!");
      setIsPlaying(false);
      toastDisplayedRef.current = true;

      // Navigate after 2 seconds
      setTimeout(() => {
        navigate("/dashboard/questionAnalysis", {
          state: { data: locationData },
        });
        toast.dismiss();
      }, 2000);
    }
  };

  useEffect(() => {
    if (location.state?.data !== undefined) {
      setLocationData(location.state?.data);
    } else {
      let data = JSON.parse(params.get("question"));
      setLocationData(data);
      setAnswerColors(Array(data?.options.length).fill(""));
    }
  }, []);

  useEffect(() => {
    if (locationData) {
      console.log("Question Details", locationData);
      startTest();
    }
  }, [locationData]);

  useEffect(() => {
    let interval;

    if (isPlaying && locationData !== undefined) {
      console.log(locationData);
      setTimer(parseInt(locationData?.displayTime, 10));
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            setIsPlaying(false);
            clearInterval(interval);
            handlerToastMessage();
            return 0;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying, locationData]);

  useEffect(() => {
    if (
      locationData !== undefined &&
      currentQuestionIndex < questions.length - 1 &&
      !isPlaying
    ) {
      const timer = setTimeout(() => {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setIsPlaying(true);
      }, 1000 * parseInt(locationData?.displayTime, 10));

      return () => clearTimeout(timer);
    }
  }, [currentQuestionIndex, questions, locationData, isPlaying]);

  useEffect(() => {
    if (timer === 1) {
      endTest();
    }
  }, [timer]);

  const startTest = async () => {
    setLoader(true);
    const payload = {
      QuestionId: locationData.questionId,
      liveSessionId: sessionDetails ? sessionDetails?.lmsSessionId : "",
    };
    let link =
      user?.role == "studioteacher"
        ? "/qsa/startQuestion/oldsession"
        : "/qsa/startQuestion/classteacher";
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      if (responseData.Error) {
        toast.error(responseData.Error.Message);
        window.history.back();
        setLoader(false);
      } else {
        setLoader(false);
        dispatch(setStartQuestion(locationData));
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
    }
  };

  const endTest = async () => {
    setEndLoader(true);
    let link =
      user?.role == "studioteacher"
        ? "/qsa/endQuestion"
        : "/qsa/endQuestion/classteacher";
    const payload = { QuestionId: locationData.questionId };
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      console.log("Clsoign Test Now");
      dispatch(setEndQuestion({}));
      setEndLoader(false);
      toast.success("Test End");
      navigate("/dashboard/questionAnalysis", {
        state: { data: locationData },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeExtendedScreenFromDifferentPage = () => {
    dispatch(setEndQuestion({}));
    if (window.screen.isExtended && newChildWindowRef) {
      newChildWindowRef.current.close();
    } else {
      console.error("Parent window not found or closed.");
    }
  };

  const HandleEndTest = () => {
    if (!loader) {
      endTest();
      closeExtendedScreenFromDifferentPage();
    }
  };

  const showCorrectAnswer = () => {
    setShowAns(true);
   if(window.screen.isExtended){
    console.log(newChildWindowRef);
    let query = `?question=${JSON.stringify({
      ...locationData,
      showAnswer: true,
    })}`;
    newChildWindowRef.current.location.href =
      `${ApiCalling.extensionUrl}/singlequiz` + query;
   }
  };

  return (
    <>
      <div className="kbc py-5">
        {/* <ToastContainer /> */}
        {loader ? (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Spinner /> <br />
            <h4>Loading your question please wait ...</h4>
          </div>
        ) : (
          <Container>
            {/* Object.keys(locationData[currentQuestionIndex]).includes("displayTime") && */}
            {locationData && locationData.displayTime ? (
              <Row className="d-flex justify-content-center align-items-center">
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="p-3 d-flex flex-column align-items-center">
                    <div className="timerContainer">
                      <p>{timer}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div style={{ marginTop: "8%" }}></div>
            )}

            <Row className="justify-content-center gy-4 mt-3">
              <Col xs={12}>
                <div className="question">
                  <h5>{locationData?.question}</h5>
                </div>
              </Col>

              {locationData?.options &&
                locationData?.options.map((option, index) => (
                  <Col md={5} key={index} style={{ cursor: "pointer" }}>
                    <div
                      className={`answer ${
                        showAns &&
                        locationData.rightAns === locationData.options[index]
                          ? "correct"
                          : ""
                      }`}
                      // onClick={() => handleOptionClick(index)}
                    >
                      <h5>{option}</h5>
                    </div>
                  </Col>
                ))}
            </Row>

            <Row className="mx-3" style={{ marginTop: "10%" }}>
              <Col md={2}>
                <button
                  className="primaryBtn p-3"
                  onClick={() => {
                    showCorrectAnswer();
                  }}
                >
                  {labels.questions[selectedLang].showAnswer}
                </button>
              </Col>

              <Col className="d-flex justify-content-end">
                {endLoader ? (
                  <Spinner />
                ) : (
                  <button
                    className="primaryBtn p-3"
                    onClick={() => {
                      HandleEndTest();
                    }}
                  >
                    {labels.questions[selectedLang].endQuestion}
                  </button>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

export default SingleQueQuiz;
