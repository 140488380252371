import React from 'react';
import { Container } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons"

const TestSubmitedPage = () => {
  return (
    <>
     <div className="py-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '90vh' }}>
      <div style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        padding: "5%", // Adjust padding based on percentage
        width: "90%", // Adjust width based on percentage
        textAlign: "center",
        maxWidth: "600px", // Set a maximum width for responsiveness
        margin: "0 auto" // Center the container within the available space
      }}>
        <Icon.CheckCircle style={{ color: "green", marginBottom: "18px" }} size={55} />
        <h3 style={{ color: "green", fontWeight: "600"}}>Congratulations !</h3>
        <h5 style={{ color: "grey" }}>Your test has been submitted successfully.</h5>

        <h2 style={{ fontWeight: "700", marginTop:"35px" }}>Thank You !</h2>
      </div>
    </div>
    </>
  )
}

export default TestSubmitedPage
