import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { ApiCalling } from "../../Services/pool";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { showToastSuccess } from "../Utilities/toastutils";
import * as Icon from "react-bootstrap-icons";
import useGetAllSubjects from "../ReuseApi/useGetAllSubjects";
import useGetAllClass from "../ReuseApi/useGetAllClass";
import useGetAllTopic from "../ReuseApi/useGetAllTopics";
import Spinners from "../../Components/Utilities/Spineers";
import { toast } from "react-toastify";
import labels from "../../config/labels.json";

const AddQuestions = () => {
  // for navigation ----
  const navigate = useNavigate();
  const user = useSelector((state) => state.userData.loggedInUser);
  const sessionDetails = useSelector((state) => state.userData.sessionDetails);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const location = useLocation();

  const [addque, setAddQue] = useState("");
  const [queoptions, setQueOptions] = useState([]);
  const [correctans, setCorrectAns] = useState("");
  const [displaytime, setDisplayTime] = useState("");
  const [mcqOptions, setMcqOptions] = useState([]);
  const [questionType, setQuestionType] = useState("truefalse");
  const [currentMcqOption, setCurrentMcqOption] = useState("");
  const [selectclass, setSelectClass] = useState({
    value: Number(user.classId),
    label: user.className,
  });
  const [selectsubject, setSelectSubject] = useState({
    value: user?.subjectId[0],
    label: user?.subjectName[0],
  });
  const [selecttopic, setSelectTopic] = useState("");
  const [selectDifficultyLevel, setSelectDifficultyLevel] = useState("");
  const [Texonomy, setTexonomy] = useState("");
  const [activeButton, setActiveButton] = useState("truefalse");
  const [showTimer, setShowTimer] = useState(false);
  const [selectedMcq, setselectedMcq] = useState("");
  const allclassoptions = useGetAllClass();
  const allsubjectoptions = useGetAllSubjects();
  const alltopicoptions = useGetAllTopic();
  const allDifficultyLavel = [
    { value: "easy", label: "Easy" },
    { value: "medium", label: "Medium" },
    { value: "hard", label: "Hard" },
  ];
  const texonomyOption = [
    { id: "Create", value: "Create", label: "Create" },
    { id: "Evaluate", value: "Evaluate", label: "Evaluate" },
    { id: "Analyze", value: "Analyze", label: "Analyze" },

    { id: "Apply", value: "Apply", label: "Apply" },
    { id: "Understand", value: "Understand", label: "Understand" },
    { id: "Remember", value: "Remember", label: "Remember" },
  ];

  // for validation state
  const [classError, setClassError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [topicError, setTopicError] = useState("");
  const [difficultyLevelError, setDifficultyLevelError] = useState("");
  const [TexonomyError, setTexonomyError] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [mcqOptionsError, setMcqOptionsError] = useState("");
  const [trueFalseError, setTrueFalseError] = useState("");
  const [displaytimeError, setDisplayTimeError] = useState("");
  const [loader, setloader] = useState(false);

  console.log("session  details", location);

  // for true/false and mcq tab
  const handleQuestionTypeChange = (type) => {
    setQuestionType(type);
    setActiveButton(type);
    setTrueFalseError("");
    setMcqOptionsError("");
    setQuestionError("");
    setDisplayTimeError("");
  };

  // for add mcq option
  const handleMcqOptionSubmit = (e) => {
    e.preventDefault();
    if (mcqOptions.length <= 4) {
      if (currentMcqOption.trim() !== "") {
        setMcqOptions((prevOptions) => [...prevOptions, currentMcqOption]);
        setCurrentMcqOption("");
      }
    } else {
      toast.error("Cannot add more than 5 options");
    }
  };

  // for onchange mcq options
  const handleMcqOptionChange = (e) => {
    setCurrentMcqOption(e.target.value);
    setActiveButton("mcq");
    setMcqOptionsError("");
  };

  // for true or false
  const handleTrueFalseChange = (e) => {
    const selectedValue = e.target.value;

    setQueOptions((options) =>
      options.includes(selectedValue)
        ? options.filter((option) => option !== selectedValue)
        : [...options, selectedValue]
    );

    setCorrectAns(selectedValue);
    setActiveButton("truefalse");
    setTrueFalseError("");
  };

  // handler function for display time
  const handleDisplayTimeChange = (value) => {
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = Math.min(
      Math.max(parseInt(numericValue, 10) || 0, 0),
      60
    );
    setDisplayTime(limitedValue.toString());

    // Check if the entered value is within the valid range and clear the error message
    if (
      !/^\d+$/.test(value) ||
      (parseInt(numericValue, 10) > 60 && value !== "60")
    ) {
      setDisplayTimeError(
        "Display time must be a two-digit number between 0 and 60 seconds."
      );
    } else {
      setDisplayTimeError("");
    }
  };

  // for handler submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error messages
    setClassError("");
    setSubjectError("");
    setTopicError("");
    setDifficultyLevelError("");
    setTexonomyError("");
    setQuestionError("");
    setMcqOptionsError("");
    setTrueFalseError("");
    setDisplayTimeError("");

    // Validate Select dropdowns
    if (!selectclass) {
      setClassError("Please select a class.");
    } else if (!selectsubject) {
      setSubjectError("Please select a subject.");
    } else if (!selecttopic) {
      setTopicError("Please select a topic.");
    } else if (!selectDifficultyLevel) {
      setDifficultyLevelError("Please select difficulty level.");
    } else if (!addque) {
      setQuestionError("Please enter a question.");
    } else if (!displaytime && showTimer) {
      setDisplayTimeError("Please enter display time in second");
    } else {
      // for payload
      let payload = {
        liveSessionId: sessionDetails ? sessionDetails?.lmsSessionId : "",
        classId: selectclass?.value,
        subjectId: selectsubject?.value,
        topicId: selecttopic?.value,
        className: selectclass?.label,
        subjectName: selectsubject?.label,
        bloomsTaxonomyId: Texonomy.id,
        bloomsTaxnomyWeightage: Texonomy.label,
        topicName: selecttopic.label,
        difficultyLevel: selectDifficultyLevel?.value,
        question: addque ? addque : "",
        questionType: questionType,
        options: [],
        rightAns: "",
        displayTime: displaytime ? displaytime : "",
        createdById: user.userId,
        createdByName: user.name,
        createdByContactNo: user.mobile,
        testName: location.state !== null ? location.state.test.testName : "",
        testId: location.state !== null ? location.state.test.testId : "",
      };

      console.log(questionType);

      if (questionType === "truefalse") {
        const trueFalseValue = document.querySelector(
          'input[name="trueFalseRadio"]:checked'
        );
        if (!trueFalseValue) {
          setQuestionError("Please select True or False.");
        } else {
          payload.options = ["true", "false"];
          payload.rightAns = correctans;
        }
      } else if (questionType === "mcq") {
        // Validate MCQ options
        if (mcqOptions.length === 0) {
          setMcqOptionsError("Please add At least one MCQ option.");
        } else if (mcqOptions.length < 3) {
          setMcqOptionsError("Please Add At least four MCQ option");
        } else {
          payload.options = mcqOptions;

          if (!selectedMcq) {
            setMcqOptionsError("Please select the correct answer for the MCQ.");
            return;
          } else {
            payload.rightAns = selectedMcq;
          }
        }
      }

      setTimeout(async () => {
        if (
          classError ||
          subjectError ||
          topicError ||
          questionError ||
          displaytimeError ||
          mcqOptionsError ||
          trueFalseError ||
          difficultyLevelError ||
          (questionType == "mcq" && !selectedMcq)
        ) {
          return;
        }
        setloader(true);
        try {
          const link =
            user.role !== "studioteacher"
              ? `${ApiCalling.base_url_v1}${ApiCalling.paths.AddQuestions}`
              : `${ApiCalling.base_url_v1}${ApiCalling.paths.AddQuestionsStudioTeacher}`;
          const response = await fetch(link, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          });
          const responseData = await response.json();
          console.log("responcedata", responseData);
          if (responseData.status === 200) {
            window.history.back();
            setloader(false);

            showToastSuccess("Added Successfully");
          } else {
            toast.error(responseData.error);
            setloader(false);
          }
        } catch (error) {
          setloader(false);
          console.error("Error:", error);
        }
      }, 500);
    }
  };

  if (loader) {
    return <Spinners />;
  }
  return (
    <div className="kbc py-4">
      <Row>
        <Col md={12}>
          <Button
            className="secondaryBtn me-3"
            style={{ fontSize: "15px" }}
            onClick={() => window.history.back()}
          >
            <Icon.ArrowLeftShort className="me-1" size={18} />
            {labels.addQuestion[selectedLang].back}
          </Button>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col md={12} className="d-flex justify-content-center">
            <h4 style={{ textDecoration: "underline", marginBottom: "10px" }}>
              {labels.addQuestion[selectedLang].addQuestionHere}
            </h4>
          </Col>
        </Row>

        <Row className="mt-3 px-5 md-px-0">
          <Col md={4}>
            <Form.Label className="fw-bold">
              {labels.addQuestion[selectedLang].selectClass}{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <Select
              value={selectclass}
              onChange={(selectedClass) => {
                console.log("selectlass", selectedClass);
                setSelectClass(selectedClass);
                setClassError("");
                setSelectSubject("");
              }}
              options={allclassoptions}
              placeholder="Select"
            />
            {classError && <div className="text-danger">{classError}</div>}
          </Col>
          <Col md={4}>
            <Form.Label className="fw-bold">
              {labels.addQuestion[selectedLang].selectSubject}{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <Select
              value={selectsubject}
              onChange={(selectedSubject) => {
                setSelectSubject(selectedSubject);
                setSubjectError("");
                setSelectTopic("");
              }}
              options={allsubjectoptions.filter(
                (row) =>
                  selectclass &&
                  row.ClassId &&
                  row.ClassId === selectclass.value
              )}
              placeholder="Select"
              isDisabled={!selectclass}
            />
            {subjectError && <div className="text-danger">{subjectError}</div>}
          </Col>
          <Col md={4}>
            <Form.Label className="fw-bold">
              {labels.addQuestion[selectedLang].selectTopic}{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <Select
              value={selecttopic}
              onChange={(selectedTopic) => {
                setSelectTopic(selectedTopic);
                setTopicError("");
              }}
              options={alltopicoptions.filter(
                (row) =>
                  selectsubject &&
                  row.SubjectId &&
                  row.SubjectId === selectsubject.value
              )}
              placeholder="Select"
              isDisabled={!selectsubject}
            />
            {topicError && <div className="text-danger">{topicError}</div>}
          </Col>

          {/* Difficulty Level */}
          <Row className="mt-3">
            <Col sm={12} md={5}>
              <Form.Label className="fw-bold">
                {labels.addQuestion[selectedLang].selectDifficulty}{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Select
                value={selectDifficultyLevel}
                onChange={(selectedDifficultyLevel) => {
                  setSelectDifficultyLevel(selectedDifficultyLevel);
                  setDifficultyLevelError("");
                }}
                options={allDifficultyLavel}
                placeholder="Select"
                isDisabled={!selecttopic}
              />
              {difficultyLevelError && (
                <div className="text-danger">{difficultyLevelError}</div>
              )}
            </Col>
            <Col sm={12} md={5}>
              <Form.Label className="fw-bold">
                {labels.addQuestion[selectedLang].bloomTaxonomy}{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Select
                value={Texonomy}
                onChange={(texonomy) => {
                  setTexonomy(texonomy);
                  setTexonomyError("");
                }}
                options={texonomyOption}
                placeholder="Select"
                isDisabled={!selectDifficultyLevel}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="fw-bold">
                  {labels.addQuestion[selectedLang].addQuestion}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={3}
                  value={addque}
                  name="question"
                  onChange={(e) => {
                    setAddQue(e.target.value);
                    setQuestionError("");
                  }}
                  placeholder={labels.addQuestion[selectedLang].enterQuestion}
                />
              </Form.Group>
              {questionError && (
                <div className="text-danger">{questionError}</div>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={6} md={5} className="mt-2">
              <button
                className={`secondaryBtn w-100  ${
                  activeButton == "truefalse" ? "active" : ""
                }`}
                onClick={() => handleQuestionTypeChange("truefalse")}
              >
                {labels.addQuestion[selectedLang].trueFalse}
              </button>
            </Col>

            <Col sm={6} md={5} className="mt-2">
              <button
                className={`secondaryBtn w-100 ${
                  activeButton == "mcq" ? "active" : ""
                }`}
                onClick={() => handleQuestionTypeChange("mcq")}
              >
                {labels.addQuestion[selectedLang].mcq}
              </button>
            </Col>
            <Row md={12} className="mt-3">
              <Col md={6}>
                {questionType === "truefalse" && (
                  <>
                    <p className="fw-bold">
                      {labels.addQuestion[selectedLang].selectAnswer}
                    </p>
                    <Form.Check
                      type="radio"
                      label="True"
                      name="trueFalseRadio"
                      id="trueRadio"
                      value="true"
                      onChange={handleTrueFalseChange}
                    />
                    <Form.Check
                      type="radio"
                      label="False"
                      name="trueFalseRadio"
                      id="falseRadio"
                      value="false"
                      onChange={handleTrueFalseChange}
                    />
                  </>
                )}
                {trueFalseError && (
                  <div className="text-danger">{trueFalseError}</div>
                )}
              </Col>
              <Col md={6}>
                {questionType === "mcq" && (
                  <>
                    <Form onSubmit={handleMcqOptionSubmit}>
                      <Form.Control
                        type="text"
                        placeholder={
                          labels.addQuestion[selectedLang].enterMCQQuestion
                        }
                        value={currentMcqOption}
                        onChange={handleMcqOptionChange}
                      />
                    </Form>
                    <p className="fw-bold">
                      {labels.addQuestion[selectedLang].selectAnswer}
                    </p>
                    {mcqOptions.map((option, index) => (
                      <Col
                        key={index}
                        className="d-flex justify-content-between mt-2"
                      >
                        <Form.Check
                          type="radio"
                          label={`${index + 1}) ${option}`}
                          name="optionsRadio"
                          value={option}
                          onChange={(event) => {
                            setselectedMcq(event.target.value);
                          }}
                        />
                        <Icon.XCircle
                          className="me-1"
                          size={18}
                          onClick={() => {
                            mcqOptions.splice(index, 1);
                            setMcqOptions([...mcqOptions]);
                          }}
                        />
                      </Col>
                    ))}
                    {mcqOptionsError && (
                      <div className="text-danger">{mcqOptionsError}</div>
                    )}
                  </>
                )}
              </Col>
            </Row>

            {/* Add timer checkbox */}
            <Row className="mt-3">
              <Col sm={12} md={12} lg={6}>
                <Form>
                  <Form.Check
                    inline
                    label={labels.addQuestion[selectedLang].wannaSetTime}
                    name="showTimer"
                    type="checkbox"
                    className="fw-bold"
                    onChange={(e) => setShowTimer(e.target.checked)}
                  />
                </Form>
              </Col>
            </Row>

            {/* Shows when showTimer is true */}
            {showTimer ? (
              <Row className="mt-3">
                <Col md={12} lg={6}>
                  <p className="fw-bold">
                    {labels.addQuestion[selectedLang].selectDisplayTime}
                  </p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={displaytime}
                      name="question"
                      onChange={(e) => {
                        handleDisplayTimeChange(e.target.value);
                        setDisplayTimeError("");
                      }}
                      placeholder={labels.addQuestion[selectedLang].enterTime}
                    />
                  </Form.Group>
                  {displaytimeError && (
                    <div className="text-danger">{displaytimeError}</div>
                  )}
                </Col>
              </Row>
            ) : null}
          </Row>
        </Row>
        <hr className="w-100" />
        <Row className="mt-3">
          <Col md={12} className="d-flex justify-content-end">
            <Button className="secondaryBtn px-5" onClick={handleSubmit}>
              {labels.addQuestion[selectedLang].submit}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddQuestions;
