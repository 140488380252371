import React, { useEffect, useState } from "react";
import { ApiCalling } from "../../Services/pool";
import CustomTable from "../../Common/Table/CustomTable";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionDetails,
  setSessionStartTime,
  setSessionEndTime,
  emptySession,
} from "../../Feature/Sidebar/UserSlice";
import { toast } from "react-toastify";
import labels from "../../config/labels.json";
import { Button, Row, Col, Form, Card } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const TestList = () => {
  const [sessionList, setSessionList] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userData.loggedInUser);
  const sessionDetails = useSelector((state) => state.userData.sessionDetails);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [filterList, setFilterList] = useState();
  const [searchStudent, setSearchStudent] = useState();

  useEffect(() => {
    if (sessionDetails) {
      getSessionList();
    }
  }, [sessionDetails]);

  const getSessionList = async () => {
    let payload = {};

    if (user.role === "studioteacher") {
      payload = {
        ...payload,
        teacherId: user.userId,
      };
    }

    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.testList}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    console.log("dastdasv", responseData);
    setSessionList(responseData);
    setFilterList(responseData.data);
  };

  const headerData = [
    { header: labels.testList[selectedLang].srNo, field: "srNo" },
    { header: labels.testList[selectedLang].testName, field: "sessionName" },
    {
      header: labels.testList[selectedLang].className,
      field: "sessionStartTime",
    },
    {
      header: labels.testList[selectedLang].topicName,
      field: "sessionEndTime",
    },
    {
      header: labels.testList[selectedLang].difficulty,
      field: "sessionEndDate",
    },
    { header: labels.testList[selectedLang].action, field: "action" },
  ];

  const sessionDataList =
    filterList !== undefined && filterList !== null && filterList.length > 0
      ? filterList.map((itm, index) => {
          return {
            srNo: index + 1,
            sessionName: itm?.testName,
            // sessionStartDate: moment(itm?.sessionStartTime).format(
            //   "DD-MMM-YYYY"
            // ),
            sessionEndDate: itm.difficultyLevel,
            sessionStartTime: itm?.className,
            sessionEndTime: itm.topicName,
            action: (
              <div style={{ display: "flex" }}>
                <button
                  className="primaryBtn w-50 m-2"
                  onClick={() => {
                    navigate("/dashboard/questionListByTest", {
                      state: {
                        test: itm,
                      },
                    });
                  }}
                >
                  {labels.testList[selectedLang].view}
                </button>

                <button
                  className="primaryBtn w-50 m-2"
                  onClick={() =>
                    navigate("/dashboard/kbcque", {
                      state: {
                        test: itm,
                      },
                    })
                  }
                >
                  {labels.questions[selectedLang].startTest}
                </button>
              </div>
            ),
          };
        })
      : [];

  const getSearchTest = (searchValue) => {
    let filter = sessionList.data.filter((row) => {
      return (
        row.testName
          .toLowerCase()
          .startsWith(searchValue.toLowerCase().trim()) ||
        row.testName.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
    });

    setFilterList(filter);
  };

  return (
    <>
      <div style={{ height: "90vh" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            className="primaryBtn"
            onClick={() => navigate("/dashboard/addTest")}
          >
            <Icon.PlusCircleFill className="me-2" size={16} />
            {labels.testList[selectedLang].createTest}
          </Button>
        </div>
        {sessionDataList.length > 0 && (
          <Row className="justify-content-center pt-3">
            <Col md={4}>
              <Card className="searchCard">
                <Form.Label className="searchLabel">
                  {labels.testList[selectedLang].searchByName}
                </Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="">
                    {labels.testList[selectedLang].searchName}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={searchStudent}
                    name="question"
                    onChange={(e) => {
                      getSearchTest(e.target.value);
                    }}
                    placeholder={labels.testList[selectedLang].enterTestName}
                  />
                </Form.Group>
              </Card>
            </Col>
            {/* <Col md={6}>
              <Card className="searchCard">
                <Form.Label className="searchLabel">Search By Date</Form.Label>
                <Row className="d-flex justify-content-center">
                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>Search by Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                        setEndDate("");
                        e.target.value.length > 0 && setStartDateErr(false);
                      }}
                    />
                    {startDateErr ? (
                      <p className="errMsg">Please select Start Date</p>
                    ) : (
                      ""
                    )}
                  </Col>
    
                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>Search by End Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      min={startDate ? startDate : ""}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                        e.target.value.length > 0 && setEndDateErr(false);
                      }}
                    />
                    {endDateErr ? (
                      <p className="errMsg">Please select End Date</p>
                    ) : (
                      ""
                    )}
                  </Col>
    
                  <Col md={2} className="mt-4">
                    <Button className="btn btn-submit" onClick={searchByDate}>
                      <Icon.Search className="me-2" size={14} />
                      Search
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col> */}
          </Row>
        )}
        {sessionDataList.length > 0 ? (
          <CustomTable
            headerData={headerData}
            headerRowData={sessionDataList}
          />
        ) : (
          <div className="mt-5 d-flex justify-content-center align-items-center">
            <h3>No Tests Found</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default TestList;
