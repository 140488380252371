import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    TestQuestion:{},
    isQuestionStart:false,
    questionChildRef:{}
};

const QuestionSlice = createSlice({
    name: 'activeKey',
    initialState,
    reducers: {
        setStartQuestion(state,action){
            state.TestQuestion = action.payload
            state.isQuestionStart = true
        },
        setEndQuestion(state,action){
            state.TestQuestion = action.payload
            state.isQuestionStart = false
        },
        setChildRef(state,action){
            state.questionChildRef = action.payload
        }
    },

});


export const { setStartQuestion,setEndQuestion,setChildRef } = QuestionSlice.actions;
export default QuestionSlice.reducer;