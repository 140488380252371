import React, { useEffect, useState } from "react";
import { ApiCalling } from "../../../Services/pool";
import CustomTable from "../../../Common/Table/CustomTable";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionDetails,
  setSessionStartTime,
  setSessionEndTime,
  emptySession,
} from "../../../Feature/Sidebar/UserSlice";
import { toast } from "react-toastify";
import labels from "../../../config/labels.json";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Card,
  Spinner,
} from "react-bootstrap";

const SessionTable = () => {
  const [sessionList, setSessionList] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userData.loggedInUser);
  const sessionDetails = useSelector((state) => state.userData.sessionDetails);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const [searchQuestion, setSearchQuestion] = useState();
  const [filterList, setFilterList] = useState();
  const [loader, setLoader] = useState(false);
  const [sessionLoader, setSessionLoader] = useState(false);

  useEffect(() => {
    if (sessionDetails) {
      getSessionList();
    }
  }, [sessionDetails]);

  const getSessionList = async () => {
    setSessionLoader(true);
    let payload = {};

    if (user.role === "studioteacher") {
      payload = {
        ...payload,
        teacherId: user.userId,
      };
    }

    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.getSessionList}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    setSessionList(responseData);
    setFilterList(responseData.data);
    setSessionLoader(false);
  };

  const getTime = (time) => {
    const timeSplit = time.split(":");
    if (timeSplit[0] > 12) {
      return `${timeSplit[0] - 12}:${timeSplit[1]} PM`;
    } else {
      return `${timeSplit[0]}:${timeSplit[1]} AM`;
    }
  };

  const getStartTime = (time) => {
    if (time) {
      let [date_part, time_part] = time?.split("T");
      const timeSplit = time_part?.split(":");
      if (timeSplit[0] > 12) {
        return `${timeSplit[0] - 12}:${timeSplit[1]} PM`;
      } else {
        return `${timeSplit[0]}:${timeSplit[1]} AM`;
      }
    }
  };

  const headerData = [
    { header: labels.sessionList[selectedLang].srNo, field: "srNo" },
    {
      header: labels.sessionList[selectedLang].sessionName,
      field: "sessionName",
    },
    {
      header: labels.sessionList[selectedLang].startDate,
      field: "sessionStartDate",
    },
    {
      header: labels.sessionList[selectedLang].endDate,
      field: "sessionEndDate",
    },
    {
      header: labels.sessionList[selectedLang].sessionStartTime,
      field: "sessionStartTime",
    },
    {
      header: labels.sessionList[selectedLang].sessionEndTime,
      field: "sessionEndTime",
    },
    { header: labels.sessionList[selectedLang].action, field: "action" },
  ];

  /*===New*/
  const startSession = async (session) => {
    setLoader(true);
    const dateString = "2024-05-20T13:58";
    const date = new Date(session.sessionStartTime);
    const time = date.toTimeString();

    try {
      let start = new Date(session.sessionStartTime).getTime() / 1000;
      let now = new Date().getTime() / 1000;

      console.log("Start", start);
      console.log("Now", now);

      if (start < now) {
        let payload = {
          sessionId: session.sessionId,
        };

        const response = await fetch(
          `${ApiCalling.base_url_v1}${ApiCalling.paths.startSession}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          }
        );
        const responseData = await response.json();
        if (responseData.status === 200) {
          if (responseData.data.Error === null) {
            dispatch(
              setSessionDetails({
                ...session,
                lmsSessionId: responseData.data.EntityId,
              })
            );
            dispatch(setSessionStartTime(new Date().toLocaleTimeString()));
            setLoader(false);
          } else {
            toast.error(responseData.data.Error.Message);
            setLoader(false);
          }
        }
      } else {
        toast.error("Session Start date and time condition not met");
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoader(false);
    }
  };

  const endSession = async (session) => {
    setLoader(true);
    let payload = {
      sessionId: session.sessionId,
      lmsProductId: session.lmsProductId,
    };

    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.endSession}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (responseData.status === 200) {
      if (responseData.data.Error === null) {
        dispatch(setSessionEndTime(new Date().toLocaleTimeString()));
        dispatch(emptySession());
        setLoader(false);
      } else {
        toast.error(responseData.data.Error.Message);
        setLoader(false);
      }
    }
  };

  const sessionDataList =
    filterList !== undefined && filterList !== null && filterList.length > 0
      ? filterList.map((itm, index) => {
          return {
            srNo: index + 1,
            sessionName: itm?.sessionName,
            sessionStartDate: moment(itm?.sessionStartTime).format(
              "DD-MMM-YYYY"
            ),
            sessionEndDate: moment(itm?.sessionEndTime).format("DD-MMM-YYYY"),
            sessionStartTime: getStartTime(itm.sessionStartTime),
            sessionEndTime: getStartTime(itm.sessionEndTime),
            action: (
              <div>
                {loader ? (
                  <center>
                    <Spinner />
                  </center>
                ) : (
                  <>
                    {sessionDetails &&
                      sessionDetails.sessionId !== itm.sessionId && (
                        <button
                          className="primaryBtn mx-auto"
                          onClick={() => startSession(itm)}
                        >
                          {labels.sessionList[selectedLang].startSession}
                        </button>
                      )}
                    {sessionDetails &&
                      sessionDetails.sessionId === itm.sessionId && (
                        <button
                          className="sessionLive mx-auto"
                          onClick={() => endSession(itm)}
                        >
                          {labels.sessionList[selectedLang].endSession}
                        </button>
                      )}
                  </>
                )}
              </div>
            ),
          };
        })
      : [];

  const getSearchQuestion = (searchValue) => {
    let filter = sessionList.data.filter((row) => {
      return (
        row.sessionName
          .toLowerCase()
          .startsWith(searchValue.toLowerCase().trim()) ||
        row.sessionName.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
    });

    setFilterList(filter);
  };

  return (
    <>
      <div className="py-5" style={{ height: "90vh" }}>
        {sessionDataList.length > 0 && (
          <Row className="justify-content-center pt-3">
            <Col md={4}>
              <Card className="searchCard">
                <Form.Label className="searchLabel">
                  Search By Session Name
                </Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="">
                    Search Session
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={searchQuestion}
                    name="question"
                    onChange={(e) => {
                      getSearchQuestion(e.target.value);
                    }}
                    placeholder="Enter Session Name"
                  />
                </Form.Group>
              </Card>
            </Col>
            {/* <Col md={6}>
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search By Date
              </Form.Label>
              <Row className="d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                    setEndDate("");
                    e.target.value.length > 0 && setStartDateErr(false);
                  }}
                />
                {startDateErr ? (
                  <p className="errMsg">Please select Start Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  min={startDate ? startDate : ""}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                    e.target.value.length > 0 && setEndDateErr(false);
                  }}
                />
                {endDateErr ? (
                  <p className="errMsg">Please select End Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={2} className="mt-4">
                <Button className="btn btn-submit" onClick={searchByDate}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
            </Card>
          </Col> */}
          </Row>
        )}
        {sessionLoader ? (
          <center>
            <Spinner />
          </center>
        ) : sessionDataList.length > 0 ? (
          <CustomTable
            headerData={headerData}
            headerRowData={sessionDataList}
          />
        ) : (
          <div className="mt-5 d-flex justify-content-center align-items-center">
            <h5>No Sessions Available</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default SessionTable;
