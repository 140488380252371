import React, { useEffect, useState } from "react";
import { Accordion, Button, Modal, Spinner, Row, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveKey } from "../../Feature/Sidebar/ActiveKeySlice";
import logo from "../../Assets/odish-logo.png";
import { makeStyles } from "@mui/styles";
import labels from "../../config/labels.json";
import { setUser } from "../../Feature/Sidebar/UserSlice";
import {
  emptySession,
  setSessionEndTime,
} from "../../Feature/Sidebar/UserSlice";
import { setEndQuestion } from "../../Feature/Sidebar/Questionslice";
import { ApiCalling } from "../../Services/pool";
import { io } from "socket.io-client";

const socket = io(ApiCalling.socketUrl.baseUrl);

const Sidebar = () => {
  const activeKeyRedux = useSelector(
    (state) => state.activeData.activeNumberKey
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();

  const user = useSelector((state) => state.userData.loggedInUser);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const session = useSelector((state) => state.userData.sessionDetails);
  const isRunning = useSelector((state) => state.userData.isSessionRunnig);
  const isQuestionRunning = useSelector(
    (state) => state.question.isQuestionStart
  );
  const questionDetails = useSelector((state) => state.question.TestQuestion);

  // states
  const [loader, setLoader] = useState(false);
  const [sessionOn, setSessionOn] = useState(false);
  const [questionOn, setQuestionOn] = useState(false);
  const [connectedSockets, setConnectedSockets] = useState([]);

  // functions
  const handleLogout = () => {
    if (isRunning) {
      setSessionOn(true);
    } else if (isQuestionRunning) {
      setQuestionOn(true);
    } else {
      setLoader(true);
      dispatch(setActiveKey(0));
      dispatch(setUser({}));
      navigate("/");
      setLoader(false);
    }
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  const stopSession = async () => {
    let payload = {
      sessionId: session.sessionId,
      lmsProductId: session.lmsProductId,
    };

    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.endSession}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (responseData.status === 200) {
      dispatch(setSessionEndTime(new Date().toLocaleTimeString()));
      dispatch(emptySession());
      setLoader(true);
      dispatch(setActiveKey(0));
      dispatch(setUser({}));
      navigate("/");
      setLoader(false);
    }
  };

  const stopQuestion = async () => {
    let link =
      user?.role == "studioteacher"
        ? "/qsa/endQuestion"
        : "/qsa/endQuestion/classteacher";
    const payload = { QuestionId: questionDetails.questionId };
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      dispatch(setEndQuestion({}));
      setLoader(true);
      dispatch(setActiveKey(0));
      dispatch(setUser({}));
      navigate("/");
      setLoader(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getConnectedClients();
  }, []);

  const getConnectedClients = () => {
    socket.emit("getAllConnectedClientSocket");
    socket.on("allConnectedClient", (data) => {
      let arr = [...data];
      setConnectedSockets(arr);
    });
  };

  return (
    <>
      <div className="sidenav-lg pt-4">
        <div className={styles.headerDiv}>
          <div className="my-4 d-flex flex-column justify-content-center aling-items-center">
            <img src={logo} width={55} height={60} className="mx-auto" />
            <h6
              style={{
                fontWeight: "600",
                marginBottom: "0px",
                marginTop: "3px",
              }}
            >
              {labels.menubar[selectedLang].lmsClicker}
            </h6>
          </div>
        </div>
        <hr className="w-50 mt-3 mb-4" />

        {user.role === "studioteacher" &&
          <h6 className="ms-4">{labels.menubar[selectedLang].connectedClients} :- {connectedSockets.length}</h6>}
        <div className="menus">
          <Accordion flush activeKey={activeKeyRedux?.activeKey}>
            {user.role === "admin" ||
              (user.role === "studioteacher" && user.role !== "schoollogin" && (
                <Accordion.Item
                  eventKey={5}
                  onClick={() => {
                    handleAccordionChange(
                      5,
                      5,
                      labels.menubar[selectedLang].createSession
                    );
                    navigate("/dashboard/session");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 5 ? "activeMenu" : "menuItem"
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 0 ? (
                      <Icon.BarChartFill
                        className={`me-2 icon ${styles.rowIcon}`}
                      />
                    ) : (
                      <Icon.BarChart
                        className={`me-2 icon ${styles.rowIcon}`}
                      />
                    )}
                    <div className={styles.rowName}>
                      {labels.menubar[selectedLang].createSession}
                    </div>
                  </Accordion.Header>
                </Accordion.Item>
              ))}

            {user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={0}
                onClick={() => {
                  handleAccordionChange(
                    0,
                    0,
                    labels.menubar[selectedLang].dashboard
                  );
                  navigate("/dashboard");
                }}
                className={
                  activeKeyRedux?.activeKey === 0 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 0 ? (
                    <Icon.BarChartFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.BarChart className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].dashboard}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/dashboard/home"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : "menuItem"}>
                                          <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className={`me-2 icon ${styles.rowIcon}`} /> : <Icon.HouseDoor className={`me-2 icon ${styles.rowIcon}`} />}<div className={styles.rowName}>Home</div></Accordion.Header>
                                    </Accordion.Item> */}
            {user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(
                    2,
                    2,
                    labels.menubar[selectedLang].question
                  );
                  navigate("/dashboard/quenew");
                }}
                className={
                  activeKeyRedux?.activeKey === 2 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 2 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].question}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* {user.role !== "classteacher" && (
              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(2, 2, "Add Question");
                  navigate("/dashboard/que");
                }}
                className={
                  activeKeyRedux?.activeKey === 2 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 2 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>Questions</div>
                </Accordion.Header>
              </Accordion.Item>
            )} */}

            {/* <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Other"); navigate("/dashboard/other"); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : "menuItem"}>
                                          <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.PersonFillGear className={`me-2 icon ${styles.rowIcon}`} /> : <Icon.PersonGear className={`me-2 icon ${styles.rowIcon}`} />}<div className={styles.rowName}>Other</div></Accordion.Header>
                                    </Accordion.Item> */}

            {user.role !== "studioteacher" && user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={4}
                onClick={() => {
                  handleAccordionChange(
                    4,
                    4,
                    labels.menubar[selectedLang].liveSessionPoll
                  );
                  navigate("/dashboard/sessionWiseQueAns");
                }}
                className={
                  activeKeyRedux?.activeKey === 4 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 4 ? (
                    <Icon.Broadcast className={`me-2 icon ${styles.rowIcon}`} />
                  ) : (
                    <Icon.Broadcast className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].liveSessionPoll}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role !== "classteacher" && user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={6}
                onClick={() => {
                  handleAccordionChange(
                    6,
                    6,
                    labels.menubar[selectedLang].sessionList
                  );
                  navigate("/dashboard/sessionTable");
                }}
                className={
                  activeKeyRedux?.activeKey === 6 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 6 ? (
                    <Icon.PersonFillGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.PersonGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].sessionList}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role !== "admin" && user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={7}
                onClick={() => {
                  handleAccordionChange(7, 7, "Assessment List");
                  navigate("/dashboard/testList");
                }}
                className={
                  activeKeyRedux?.activeKey === 7 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 7 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].testList}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* {user.role !== "schoollogin" && (
              <Accordion.Item
                eventKey={8}
                onClick={() => {
                  handleAccordionChange(8, 8,labels.menubar[selectedLang].lmsQuestions);
                  navigate("/dashboard/LMSQuestionList");
                }}
                className={
                  activeKeyRedux?.activeKey === 8 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 8 ? (
                    <Icon.QuestionCircleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.QuestionCircle
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>{labels.menubar[selectedLang].lmsQuestions}</div>
                </Accordion.Header>
              </Accordion.Item>
            )} */}

            {user.role == "schoollogin" && (
              <Accordion.Item
                eventKey={8}
                onClick={() => {
                  handleAccordionChange(
                    8,
                    8,
                    labels.menubar[selectedLang].displayQuestions
                  );
                  navigate("/dashboard/diplayquestion");
                }}
                className={
                  activeKeyRedux?.activeKey === 8 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 8 ? (
                    <Icon.BarChartFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.BarChart className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].displayQuestions}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role == "classteacher" && (
              <Accordion.Item
                eventKey={8}
                onClick={() => {
                  handleAccordionChange(
                    9,
                    9,
                    labels.menubar[selectedLang].studentList
                  );
                  navigate("/dashboard/studentList");
                }}
                className={
                  activeKeyRedux?.activeKey === 9 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 9 ? (
                    <Icon.PeopleFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.People className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].studentList}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {user.role == "classteacher" && (
              <Accordion.Item
                eventKey={10}
                onClick={() => {
                  handleAccordionChange(
                    10,
                    10,
                    labels.menubar[selectedLang].studentAnalytics
                  );
                  navigate("/dashboard/analytics/student");
                }}
                className={
                  activeKeyRedux?.activeKey === 10 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 10 ? (
                    <Icon.BarChartFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.BarChart className={`me-2 icon ${styles.rowIcon}`} />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].studentAnalytics}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {/* <Accordion.Item
                eventKey={10}
                onClick={() => {
                  handleAccordionChange(10, 10, labels.menubar[selectedLang].analytics);
                  navigate("/dashboard/analytics");
                }}
                className={
                  activeKeyRedux?.activeKey === 10 ? "activeMenu" : "menuItem"
                }>
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 10 ? (
                    <Icon.PersonFillGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.PersonGear
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>{labels.menubar[selectedLang].analytics}</div>
                </Accordion.Header>
              </Accordion.Item> */}

            {user.role == "classteacher" && (
              <Accordion.Item
                eventKey={11}
                onClick={() => {
                  handleAccordionChange(
                    11,
                    11,
                    labels.menubar[selectedLang].handRaise
                  );
                  navigate("/dashboard/hand-raise");
                }}
                className={
                  activeKeyRedux?.activeKey === 11 ? "activeMenu" : "menuItem"
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 11 ? (
                    <Icon.HandIndexThumbFill
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  ) : (
                    <Icon.HandIndexThumb
                      className={`me-2 icon ${styles.rowIcon}`}
                    />
                  )}
                  <div className={styles.rowName}>
                    {labels.menubar[selectedLang].handRaise}
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            )}
          </Accordion>
          
        </div>

        <div className="px-2 mt-auto">
          <Button className="btn-logout mx-auto" onClick={handleLogout}>
            {loader ? (
              <Spinner animation="border" variant="light" className="spinner" />
            ) : (
              <>
                <Icon.BoxArrowLeft className="me-2" />
                {labels.menubar[selectedLang].logout}
              </>
            )}
          </Button>
        </div>
      </div>
      <Modal
        show={sessionOn}
        onHide={() => {
          setSessionOn(false);
        }}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          style={{
            color: "black",
            width: "100%",
            height: "50px",
            // backgroundColor: "#F9D65F",
          }}
        >
          <Modal.Title style={{ fontWeight: "bold" }}>
            Live session is Ongoing !!
          </Modal.Title>
        </Modal.Header>

        <Modal.Body size="lg">
          <Row className="mb-4 m-3">
            <Col sm={12} md={12}>
              <h5>Do you want to end ongoing live session and logout ?</h5>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="text-center mt-4">
          <Button
            className="btn btn-success"
            onClick={(e) => {
              stopSession();
            }}
          >
            Yes
          </Button>
          <Button
            className="btn btn-danger"
            onClick={(e) => {
              setSessionOn(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={questionOn}
        onHide={() => {
          setQuestionOn(false);
        }}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          style={{
            color: "black",
            width: "100%",
            height: "50px",
            // backgroundColor: "#F9D65F",
          }}
        >
          <Modal.Title style={{ fontWeight: "bold" }}>
            Live Question is Ongoing !!
          </Modal.Title>
        </Modal.Header>

        <Modal.Body size="lg">
          <Row className="mb-4 m-3">
            <Col sm={12} md={12}>
              <h5>Do you want to end Question and logout ?</h5>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="text-center mt-4">
          <Button
            className="btn btn-success"
            onClick={(e) => {
              stopQuestion();
            }}
          >
            Yes
          </Button>
          <Button
            className="btn btn-danger"
            onClick={(e) => {
              setQuestionOn(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  headerDiv: {
    width: "100%",
    height: "10%",
    display: "flex",
    justifyContent: "center",
  },
  headerLogoDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitleDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.5rem",
    color: "rgba(124, 31, 168, 1)",
    fontWeight: "600",
  },
  headerLogo: {
    width: "60%",
    height: "80%",
    borderRadius: "50%",
  },
  menuDiv: {
    width: "85%",
    margin: "15px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "65vh",
  },
  menuItem: {
    width: "100%",
    padding: "12px 10px",
    margin: "14px 10px 10px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "none",
    borderRadius: "50px",
    fontWeight: "500",
    color: "#696F7B",
    "&:hover": {
      background: "#081225",
      color: "white",
      cursor: "pointer",
    },
  },
  activeItem: {
    width: "100%",
    padding: "12px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "none",
    margin: "0px 10px 0px 10px",
    borderRadius: "50px",
    backgroundColor: "#081225",
    color: "white",
  },
  rowIcon: {
    marginLeft: "10%",
    scale: 1.2,
  },
  rowName: {
    marginLeft: "5%",
    fontWeight: "600",
  },
});

export default Sidebar;
