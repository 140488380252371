// TimerDisplay.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emptySession,
  setSessionEndTime,
  setUpdateTimer,
} from "../../Feature/Sidebar/UserSlice";
import labels from "../../config/labels.json";
import { ApiCalling } from "../../Services/pool";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const useTimerComponent = () => {
  const dispatch = useDispatch();
  const isRunning = useSelector((state) => state.userData.isSessionRunnig);
  const elapsedTime = useSelector((state) => state.userData.sessionTimer);
  const session = useSelector((state) => state.userData.sessionDetails);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        dispatch(setUpdateTimer(elapsedTime + 1));
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRunning, elapsedTime, dispatch]);

  const stopSession = async () => {
    setLoader(true);
    let payload = {
      sessionId: session.sessionId,
      lmsProductId: session.lmsProductId,
    };

    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.endSession}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (responseData.status === 200) {
      dispatch(setSessionEndTime(new Date().toLocaleTimeString()));
      dispatch(emptySession());
      setLoader(false);
    } else if (responseData.status === 404) {
      dispatch(setSessionEndTime(new Date().toLocaleTimeString()));
      dispatch(emptySession());
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(responseData.message);
    }
  };

  const formatTime = (timeInSeconds) => {
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    // Add leading zeros for single-digit values
    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");
    const secondsStr = seconds.toString().padStart(2, "0");

    // Return formatted time string
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  };

  return isRunning ? (
    <div className="d-flex" style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", margin: 5 }}>
        <span>Session Name :- {session.sessionName}</span>
        <span>Time elapsed :- {formatTime(elapsedTime)} sec</span>
      </div>
      <div className="m-2 d-flex justify-content-end align-items-center">
        { loader ? <center>
          <Spinner />
        </center> : <button
          className="sessionLive"
          onClick={() => {
            stopSession();
          }}
        >
          {labels.sessionList[selectedLang].endSession}
        </button> }
      </div>
    </div>
  ) : null;
};

export default useTimerComponent;
