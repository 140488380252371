import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { io } from "socket.io-client";
import { ApiCalling } from "../../Services/pool";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const socket = io(ApiCalling.socketUrl.baseUrl);

const DisplayQuestionStudent = () => {
  const navigate = useNavigate();
  const [QuestionData, setQuestionData] = useState("");
  const [isPlaying, setIsPlaying] = useState(true);
  const [timer, setTimer] = useState(0);
  const [answeByQuestion, setAnsweByQuestion] = useState()
  const currentUrl = window.location.href;
  // Use URLSearchParams to parse the query parameters
  const urlParams = new URLSearchParams(currentUrl.split("?")[1]);
  // Get individual parameters
  const classId = urlParams.get("classId");
  const subjectId = urlParams.get("subjectId");
  const studentId = urlParams.get("studentId");

  useEffect(() => {
    GetQuestionData();
  }, []);

  useEffect(() => {
    let interval;

    if (isPlaying) {
      setTimer(parseInt(QuestionData?.displayTime, 10));
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            setIsPlaying(false);
            clearInterval(interval);

            return 0;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying, QuestionData]);

  const GetQuestionData = () => {
    socket.on("sharedDataFromTeacher", (data) => {
      console.log("data====>", data);
      if (data && data.data.length > 0) {
        setAnsweByQuestion(data)
        // navigate("/dashboard/shareResult", {
        //   state: data,
        // });
      }
    });

    socket.emit("getQuestionOnStudentScreen", {
      classId: classId,
      subjectId: subjectId,
      studentId: studentId,
    });

    socket.on(`sendQuestionToStudentScreen`, (data) => {
      console.log("question data", data);
      if (data) {
        setQuestionData(data[0]);
        setAnsweByQuestion()
      } else if (!data) {
        setQuestionData("");
      }
    });
    console.log("Getting data from socket");
  };

  return (
    <>
      <>
        <div className="kbc py-5">
          <Container>
            {/* Object.keys(QuestionData[currentQuestionIndex]).includes("displayTime") && */}
            {QuestionData.displayTime ? (
              <Row className="d-flex justify-content-center align-items-center">
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="p-3 d-flex flex-column align-items-center">
                    <div className="timerContainer">
                      <p>{timer}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div style={{ marginTop: "5%" }}></div>
            )}

            <Row className="justify-content-center gy-4 mt-3">
              <Col xs={12}>
                {QuestionData?.question ? (
                  <div className="question">
                    {" "}
                    <h5>{QuestionData?.question}</h5>{" "}
                  </div>
                ) : !answeByQuestion && (
                  <h5 style={{ textAlign: "center" }}>No Live Question</h5>
                )}
              </Col>

              {QuestionData?.options &&
                QuestionData?.options.map((option, index) => (
                  <Col md={5} key={index} style={{ cursor: "pointer" }}>
                    <div className={`answer `}>
                      <h5>{option}</h5>
                    </div>
                  </Col>
                ))}
            </Row>

            <Row>
            {answeByQuestion && answeByQuestion.data.length > 0 && (
             <div className="d-flex justify-content-center align-items-center" style={{
              flexDirection:'column'
             }}>
                <h3>Result of the test conducted</h3>
               <div
                className="d-flex justify-content-evenly"
                style={{
                  width: "80%",
                  margin: "auto",
                  marginBottom: "2%",
                  height: "50vh",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid black",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    width: "30%",
                  }}
                >
                  <span className="">No of student responded</span>
                  <span className="mt-2 fw-bold fs-4">
                    {answeByQuestion.data.length}/{answeByQuestion.studentCount}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid black",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    width: "30%",
                  }}
                >
                  <span className="">Correct Answer</span>
                  <span className="mt-2 fw-bold fs-4">
                    {(answeByQuestion.data.filter((row) => row.isAnswerCorrect)
                      .length /
                      answeByQuestion.data.length) *
                      100}
                    %
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid black",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    width: "30%",
                  }}
                >
                  <span className="">Wrong Answer</span>
                  <span className="mt-2 fw-bold fs-4">
                    {(answeByQuestion.data.filter(
                      (row) => row.isAnswerCorrect === false
                    ).length /
                      answeByQuestion.data.length) *
                      100}
                    %
                  </span>
                </div>
              </div>
             </div>
            )}
            </Row>
          </Container>
        </div>
      </>
    </>
  );
};

export default DisplayQuestionStudent;
