import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import sidebarReducer from '../Feature/Sidebar/ActiveKeySlice';
import AllQuestionReducer from '../Components/Questions/slice/AllQuestionSlice'
import UserReducer from '../Feature/Sidebar/UserSlice';
import QuestionReducer from '../Feature/Sidebar/Questionslice'

const reducers = combineReducers({
  activeData: sidebarReducer,
  allQuestions: AllQuestionReducer,
  userData:UserReducer,
  question:QuestionReducer
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);



// import {configureStore} from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice'

// import {setupListeners} from '@reduxjs/toolkit/query';

// import {persistStore, persistReducer} from 'redux-persist';
// import AsyncStorage from '@react-native-async-storage/async-storage';

// import {combineReducers} from '@reduxjs/toolkit';

// const initialState = {};
// const persistConfig = {
//   key: 'root',
//   storage: AsyncStorage,
// };
// const combinedReducer = combineReducers({

// });

// const rootReducer = (state, action) => {
//   if (action.type === 'logout/setlogoutaction') {
//     state = undefined;
//   }
//   return combinedReducer(state, action);
// };

// const PersistReducer = persistReducer(persistConfig, rootReducer);
// export const store = configureStore({
//   reducer: {PersistReducer},
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });
// setupListeners(store.dispatch);
// export const persistor = persistStore(store);
