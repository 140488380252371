import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import AddQuestionModel from "../../Common/Model/AddQuestionModel";
import CustomTable from "../../Common/Table/CustomTable";
import KBCQue from "../../Common/KBC/MultipleQueQuiz";
import { useNavigate } from "react-router-dom";
import { ApiCalling } from "../../Services/pool";
import { useSelector } from "react-redux";

const Questions = () => {
  // for navigation
  const navigate = useNavigate();
  const user = useSelector((state) => state.userData.loggedInUser);

  // state for open addquestion model
  const [showaddqueModal, setShowAddQueModal] = useState(false);
  const [classTopicList, setClassTopicList] = useState([]);

  // function for close addquestion model
  const handleAddQuestionModelClose = () => {
    setShowAddQueModal(false);
  };

  useEffect(() => {
    getClassTopicList();
  }, []);

  const getClassTopicList = async () => {
    const link =
      user.role == "classteacher"
        ? `${ApiCalling.base_url_v1}${ApiCalling.paths.getClassSubjectToipic}?classId=${user.classId}`
        : `${ApiCalling.base_url_v1}${ApiCalling.paths.getClassSubjectToipic}`;
    const response = await fetch(link, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(data)
    });
    const responseData = await response.json();
    console.log("Resvdad", responseData.data);
    setClassTopicList(responseData.data);
  };

  // for table data
  const headerData = [
    { header: "Sr.No", field: "srNo" },
    { header: "Class", field: "class" },
    { header: "Subject", field: "subject" },
    { header: "Topic", field: "topic" },
    { header: "Action", field: "action" },
  ];

  const headerRowData =
    classTopicList.length > 0 &&
    classTopicList.map((row, index) => {
      return {
        srNo: index + 1,
        class: row.className,
        subject: row.subjectName,
        topic: row.topicName,
        action: (
          <span className="d-flex justify-content-center">
            <Button
              className="primaryBtn w-50"
              onClick={() =>
                navigate("/dashboard/quelist", {
                  state: {
                    subject: row.subjectId,
                    class: row.classId,
                    topic: row.topicId,
                  },
                })
              }
            >
              View
            </Button>
            <Button
              className="primaryBtn ms-2 w-50"
              onClick={() =>
                navigate("/dashboard/kbcque", {
                  state: {
                    subject: row.subjectId,
                    class: row.classId,
                    topic: row.topicId,
                  },
                })
              }
            >
              Start Test
            </Button>
          </span>
        ),
      };
    });
  return (
    <div className="AddQuestion">
      <Container>
        <Row className="mt-3">
          <Col md={12}>
            <Button
              className="primaryBtn me-3 mx-auto"
              onClick={() => navigate("/dashboard/addque")}
            >
              <Icon.PlusCircleFill className="me-2" size={16} />
              Add Question
            </Button>
          </Col>
        </Row>
        <hr className="w-100 mb-0" />
        <Row className="my-2 mx-2">
          <div className="mx-auto">
            {classTopicList.length > 0 && (
              <div className="table-wrapper ">
                <CustomTable
                  headerData={headerData}
                  headerRowData={headerRowData}
                />
              </div>
            )}
          </div>
        </Row>
        <hr className="w-100" />
      </Container>
    </div>
  );
};

export default Questions;
