import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeNumberKey: 0,
    currentIndex: 0,
};

const sidebarSlice = createSlice({
    name: 'activeKey',
    initialState,
    reducers: {
        setActiveKey(state, action) {
            state.activeNumberKey = action.payload
        },
        setCurrentIndex(state, action) {
            state.currentIndex = action.payload
        }
    },

});


export const { setActiveKey, setCurrentIndex } = sidebarSlice.actions;
export default sidebarSlice.reducer;