const environments = {
  main: {
    baseUrl: "https://serverapi.odisa.foxberry.live/v1",
  },
  staging: {
    baseUrl: "https://serverapi.odisa.foxberry.live/v1",
  },
  local: {
    baseUrl:"https://clientapi.odisa.foxberry.live/v1"
  },
};

export const ApiCalling = {
  base_url_v1: "https://clientapi.odisa.foxberry.live/v1",
  paths: {
    login: "/users/login",
    AddQuestions: "/questions/addQuestions",
    getQuestionsList: "/questions/getQuestionList",
    getStudentAnswers: "/qsa/getStudentAnswers",
    getStudentWiseGraph: "/graph/studentWiseGraph",
    getAnswerPieChart: "/graph/answersGraph",
    getOptionCountChart: "/graph/questionOptionCountGraph",
    getAnswersByQuestionGraph: "/graph/AnswersByQuestionGraph",
    getAnswersByQuestion: "/qsa/getAnswersByQuestion",
    createSession: "/session/addSession",
    getSessionList: "/session/getAllSessions",
    liveSessionList:"/integration/liveSessionList",
    endLivession:"/integration/endLiveSession",
    getTeacherList: "/users/getUserByRole",
    getClassSubjectToipic: "/questions/getClassSubjectTopic",
    getLMSquestions: "/integration/allQuestionList",
    studioGraph: "/graph/studioGraph",
    studentList:'/student/getAllStudents',
    updateStudent:'/student/updateStudent',
    startSession:"/session/start/session",
    endSession:'/session/end/session',
    AddQuestionsStudioTeacher:"/questions/addQuestions/studioTeacher",
    createTest:'/questions/create/test',
    testList:'/questions/test/list',
    startHandRaise:'/qsa/startHandraise',
    endHandRaise:'/qsa/endHandraise',
    classList:'/integration/classesListDb',
    subjectList:'/integration/subjectsListDb',
    topicList:'/integration/topicsListDb',
  },
  socketUrl: {
    baseUrl: "https://clientapi.odisa.foxberry.live",
    // baseUrl: "http://192.168.105.212:7008",
  },
  extensionUrl:'https://client.odisha.foxberry.live',
  // extensionUrl:'https://odisha.foxberry.live'
  
};